import { Button } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { requestCategories } from '../../requests/requests'
import { useIntl } from 'react-intl'
import { Category } from '../../models/models'
import SingleCategory from './singleCategory'
import AddCategoryModal from '../../components/add-categories/addCategoryModal'
import { BreadcumbsSection } from '../../components/BreadcumbsSection'

export function Categories() {
  const [show, setShow] = useState(false)
  const intl = useIntl()

  const {
    isLoading,
    error,
    data,
    refetch: refetchCategories,
  } = useQuery('categories', () => requestCategories(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  })

  return (
    <>
      <BreadcumbsSection title={intl.formatMessage({ id: 'CATEGORIES.CATEGORIES_TITLE' })} breadcrumbItems={[intl.formatMessage({ id: 'CATEGORIES.CATEGORIES_SUBTITLE' })]}>
        <Button className='my-2' onClick={() => setShow(true)}>
          {intl.formatMessage({ id: 'CATEGORIES.ADD_CATEGORIES' })}
        </Button>
      </BreadcumbsSection>
      <AddCategoryModal show={show} onHide={() => setShow(false)} refetch={refetchCategories} />
      {/* Begin Category list */}
      <div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
        {/* begin::Card body*/}
        <div className='card-body pt-0'>
          <div className='table-responsive'>
            {/* begin::Table*/}
            <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-175px'>{intl.formatMessage({ id: 'CATEGORIES.NAME' })}</th>
                  <th className='min-w-70px text-start'></th>
                  <th className='min-w-70px text-end'>{intl.formatMessage({ id: 'CATEGORIES.ACTIONS' })}</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {isLoading && <tr><td colSpan={3}>{intl.formatMessage({ id: 'FETCH.LOADING' })}</td></tr>}
                {error ? <tr><td colSpan={3}>{intl.formatMessage({ id: 'FETCH.ERROR' })}</td></tr> : false}
                {
                  data?.data.map((cat: Category) => <SingleCategory id={cat.id} name={cat.name} refetch={refetchCategories} key={cat.id}></SingleCategory>)
                }
              </tbody>
            </table>
            {/* end::Table*/}
          </div>
        </div>
        {/* end::Card body*/}
      </div>


      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 me-9 mt-4'>

        {/* Contingut dinàmin */}
        {/* <section className='card-body p-10 container'>
          <div className='d-flex justify-content-between align-items-center mb-12'>
            <h2>{intl.formatMessage({id: 'CATEGORIES.LAST_CATEGORIES'})}</h2>
          </div>
          <Row>
            {isLoading ? (
              <p>{intl.formatMessage({id: 'FETCH.LOADING'})}</p>
            ) : error ? (
              <p>{intl.formatMessage({id: 'FETCH.ERROR'})}</p>
            ) : (
              data?.data.map((cat: Category) => (
                <SingleCategory
                  id={cat.id}
                  name={cat.name}
                  key={cat.id}
                  refetch={refetchCategories}
                />
              ))
            )}
          </Row>
        </section> */}
      </div>
    </>
  )
}

import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { editProductExtension, requestCategories, requestFamilies } from '../../requests/requests'
import {
  ProductExtensionEdit,
  ProductExtensionSingle,
  TranslatableValue,
} from '../../models/productExtensions'
import { Category, Language } from '../../models/models'
import FileInput from '../../helpers/imgToBase64'
import SuperSelect from '../SuperSelect'
import { Family } from '../../models/families'
import { configStaleTime } from '../../constants'

export function EditProductExtensionModal({
  productExtensionId,
  show,
  onHide,
  refetchProducts,
  refetchProductSingle,
}) {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const productExtensionSingle: ProductExtensionSingle = queryClient.getQueryData([
    `singleProductExtension${productExtensionId}`,
  ]) as ProductExtensionSingle
  const [inPromotionChecked, setInPromotionChecked] = useState(
    productExtensionSingle ? productExtensionSingle.inPromotion : false
  )

  const { data: categoriesData } = useQuery('categories', () => requestCategories(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  })

  const {
    data: dataFamilies,
  } = useQuery('listFamilies', () => requestFamilies(), {
    refetchOnWindowFocus: false,
    staleTime: configStaleTime,
  })

  const families = dataFamilies?.data as Family[]

  // Esto lo he puesto para que detecte si hay cambio en el objeto ponga el valor nuevo (Al cargar no detectaba bien el valor correcto)
  useEffect(() => {
    if (productExtensionSingle) {
      setInPromotionChecked(productExtensionSingle.inPromotion)
    }
  }, [productExtensionSingle])

  if (!productExtensionSingle) {
    return (
      <div>
        <i className='fa fa-spinner fs-2x fa-sync fa-spin'></i>
      </div>
    )
  }

  const initialValuesProductExtension = () => {
    let initial = {
      productNameAdmin: productExtensionSingle.productNameAdmin,
      inPromotion: productExtensionSingle.inPromotion,
      imageBs64: '',
      urlImage: productExtensionSingle.urlImage,
      categories: productExtensionSingle.categories,
      newCategories: productExtensionSingle.categories.map((x) => parseInt(x as unknown as string)),
      allCategories: categoriesData?.data as Category[],
      familyId: productExtensionSingle.familyId ? productExtensionSingle.familyId : 0
      //disableWithCondition: productExtensionSingle.disableWithCondition,
    }
    // Adding dynamic initial values based on languages
    if (productExtensionSingle.promotionalText.length > 0) {
      productExtensionSingle.promotionalText.forEach((el) => {
        initial[`promotionalText${+el.language}`] = el.value
      })
    } else {
      for (let lang in Language) {
        if (!isNaN(Number(lang))) {
          initial[`promotionalText${+lang}`] = ''
          productExtensionSingle.promotionalText.push({
            value: '',
            language: Number(lang),
          } as TranslatableValue)
        }
      }
    }
    return initial
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className='text-black'>
            {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.EDIT_MODAL_TITLE' })}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={initialValuesProductExtension()}
          onSubmit={(values) => {
            const editProdExtensionObj = {
              id: productExtensionId,
              sageCode: productExtensionSingle.sageCode,
              urlImage: values.urlImage,
              productNameAdmin: values.productNameAdmin,
              inPromotion: inPromotionChecked,
              promotionalText: [] as TranslatableValue[],
              imageBs64: values.imageBs64,
              categories: values.newCategories as number[],
              familyId: values.familyId ? values.familyId : null
              //disableWithCondition: values.disableWithCondition,
            } as ProductExtensionEdit
            productExtensionSingle.promotionalText.forEach((el, index) => {
              const promotionalTextInLang = values[`promotionalText${+el.language}`]
              editProdExtensionObj.promotionalText.push({
                value: promotionalTextInLang,
                language: index,
              })
            })
            editProductExtension(editProdExtensionObj).then(() => {
              refetchProducts()
              refetchProductSingle()
              onHide()
            })
          }}
        >
          {({ handleSubmit, values, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.EDIT_MODAL_PRODUCT_NAME_ADMIN' })}
                </Form.Label>
                <Field
                  type='text'
                  name='productNameAdmin'
                  className='form-control w-100 h-auto bg-light border-0 text-black'
                />
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_PRODUCT_NAME_ADMIN' })}
                </span>
              </Form.Group>

              {productExtensionSingle &&
                productExtensionSingle.promotionalText.map((text) => (
                  <Form.Group
                    className='d-flex flex-column mb-8'
                    key={`promotionalText${text.language}`}
                  >
                    <Form.Label className='fs-4 text-black'>
                      {intl.formatMessage({
                        id: 'PRODUCT_EXTENSIONS.EDIT_MODAL_PRODUCT_PROMOTIONAL_TEXT',
                      })}
                      &nbsp;
                      {text.language === Language.English
                        ? intl.formatMessage({
                          id: 'PRODUCT_EXTENSIONS.EDIT_MODAL_PRODUCT_LANGUAGE_EN',
                        })
                        : intl.formatMessage({
                          id: 'PRODUCT_EXTENSIONS.EDIT_MODAL_PRODUCT_LANGUAGE_ES',
                        })}
                    </Form.Label>
                    <Field
                      type='text'
                      name={`promotionalText${text.language}`}
                      className='form-control w-100 h-auto bg-light border-0 text-black'
                      maxLength='15'
                    />
                    <span className='text-gray-400 mt-2'>
                      {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_PROMOTIONAL_TEXT' })}
                    </span>
                  </Form.Group>
                ))}

              <Form.Group className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.MODAL_PRODUCT.LABEL_CURRENT_IMAGE' })}
                </Form.Label>
                <img
                  className='mb-6'
                  src={values.urlImage}
                  alt=''
                  height={100}
                  style={{ maxWidth: '150px', aspectRatio: 16 / 9, objectFit: 'cover' }}
                />
              </Form.Group>

              <Form.Group controlId='imageBs64' className='d-flex flex-column mb-8'>
                <Form.Label className='fs-4 text-black'>
                  {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.MODAL_PRODUCT.LABEL_NEW_IMAGE' })}
                </Form.Label>
                <FileInput />
                <span className='text-gray-400 mt-2'>
                  {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_NEW_IMAGE' })}
                </span>
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group className='d-flex flex-column mb-8'>
                    <Form.Label className='fs-4 text-black'>
                      {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.MODAL_PRODUCT.LABEL_FAMILY' })}
                    </Form.Label>
                    <SuperSelect
                      options={families?.map((family) => ({ value: family.id, label: family.name }))}
                      placeholder={''}
                      onChange={(el) => {
                        if (el) {
                          values.familyId = el.value
                        } else {
                          values.familyId = null
                        }
                      }}
                      selectedOptions={values.familyId > 0 ? {
                        value: values.familyId,
                        label: families?.find((x) => x.id + '' === values.familyId + '')?.name,
                      } : false}
                      isMulti={false}
                      isClearable={true}
                    ></SuperSelect>
                    <span className='text-gray-400 mt-2'>
                      {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_FAMILY' })}
                    </span>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='d-flex flex-column mb-8'>
                    <Form.Label className='fs-4 text-black'>
                      {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.MODAL_PRODUCT.LABEL_CATEGORIES' })}
                    </Form.Label>
                    <SuperSelect
                      options={values.allCategories.map((cat) => ({ value: cat.id, label: cat.name }))}
                      placeholder={''}
                      onChange={(el) => {
                        values.newCategories = el.map((x) => x.value)
                      }}
                      selectedOptions={values.categories.map((catID) => ({
                        value: catID,
                        label: values.allCategories.find((x) => x.id + '' === catID + '')?.name,
                      }))}
                      isMulti={true}
                    ></SuperSelect>
                    <span className='text-gray-400 mt-2'>
                      {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_CATEGORIES' })}
                    </span>
                  </Form.Group></Col>
              </Row>

              <Form.Group className='d-flex align-items-center'>
                <Field
                  type='checkbox'
                  name='inPromotion'
                  id='inPromotion'
                  className='d-flex justify-content-start'
                  checked={inPromotionChecked}
                  onChange={(e: any) => setInPromotionChecked(e.target.checked)}
                />
                <Form.Label className='mb-0 mx-2 fs-4 text-black w-100' htmlFor='inPromotion'>
                  {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.MODAL_PRODUCT.LABEL_INPROMO' })}
                </Form.Label>
              </Form.Group>
              <span className='text-gray-400 mt-2'>
                {intl.formatMessage({ id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_IN_PROMOTION' })}
              </span>

              {/* <Form.Group className='d-flex align-items-center mt-2'>
                <Field
                  type='checkbox'
                  name='disableWithCondition'
                  id='disableWithCondition'
                  className='d-flex justify-content-start'
                />
                <Form.Label className='mb-0 mx-2 fs-4 text-black w-100' htmlFor='disableWithCondition'>
                  {intl.formatMessage({id: 'PRODUCT_EXTENSIONS.MODAL_PRODUCT.LABEL_DISABLE_WITH_CONDITIONAL'})}
                </Form.Label>
              </Form.Group>
              <span className='text-gray-400 mt-2'>
                {intl.formatMessage({id: 'PRODUCT_EXTENSIONS.HELPER_TEXT_DISABLE_WITH_CONDITIONAL'})}
              </span> */}

              <div className='d-flex justify-content-end'>
                <Button
                  type='reset'
                  className='btn btn-secondary btm-sm mt-8  me-4'
                  onClick={onHide}
                >
                  {intl.formatMessage({ id: 'BUTTON.CANCEL' })}
                </Button>
                <Button variant='primary' type='submit' className='btn btn-primary btm-sm mt-8'>
                  {intl.formatMessage({ id: 'BUTTON.SAVE' })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

import {useQuery, useQueryClient} from 'react-query'
import {Client, Product} from '../models/models'
import {requestProducts} from '../requests/requests'
import { useEffect } from 'react'
import { configStaleTime } from '../constants'

// Used in normal user views
export const useProductsAvailablesForSelectedClient = (date:Date) => {
  const queryClient = useQueryClient()
  const selectedClient: Client = queryClient.getQueryData(['selectedUser']) as Client
  const selectedDateQuery: string = queryClient.getQueryData('selectedDate') as string
  //const productsKey = selectedClient && selectedClient !== undefined ? `productsForSelectedClient${selectedClient.id}` : 'productsForSelectedClient'
  const {refetch: refetchAvailableProducts, data:availableProducts} = useQuery<Product[]>(
    [
      selectedClient && selectedClient !== undefined
        ? 'availableProductsForSelectedClient' + selectedClient.id
        : 'availableProductsForSelectedClient',
    ], // Important the 'auth.SageCode' part for identify clients for every 'distributor'
    async () => {
      const cUser: Client = selectedClient as Client
      const {data: x} = await requestProducts(cUser.id, date)
      x.forEach((p: Product) => {
        if (p.urlImage == null) {
          p.urlImage =
            'https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg'
        }
      })
      x.sort((value) => {
        return value.inPromotion ? -1 : 1
      })
      //localStorage.setItem(productsKey, JSON.stringify(x))
      return x
    },
    {staleTime: configStaleTime, enabled: !!selectedClient && date != null}
  )
  /*const availableProducts: Product[] = JSON.parse(
    selectedClient ? (localStorage.getItem(productsKey) as string) : '[]'
  )*/
  useEffect(() => {
    if (date && selectedClient) {
      refetchAvailableProducts();
    }
  }, [selectedDateQuery])
  return {availableProducts, refetchAvailableProducts, selectedClient}
}

import { useIntl } from 'react-intl'
import { useState } from 'react'
import { ConfirmDeleteModal } from '../../components/generic-confirm/Confirm'
import { Family, FamilyEdit } from '../../models/families'
import EditFamilyModal from '../../components/families/EditFamilyModal'
import { requestFamily, requestFamilyDelete, requestFamilyEdit } from '../../requests/requests'

export function SingleFamily({
  family,
  refetch,
}: {
  family: Family
  refetch: any
}) {
  const intl = useIntl()
  const [editShow, setEditShow] = useState(false)
  const [disableShow, setDisableShow] = useState(false)
  const [deleteShow, setDeleteShow] = useState(false)

  const handleConfirmDelete = () => {
    requestFamilyDelete(family.id).then(() => {
      refetch()
    })
  }

  const handleConfirmDisable = async () => {
    const familySingleData = await requestFamily(family.id);
    const familyEdit = familySingleData?.data as FamilyEdit
    familyEdit.isDisabled = !familyEdit.isDisabled;
    await requestFamilyEdit(familyEdit)
    setDisableShow(false)
    refetch()
  }

  return (
    <tr>
      <td>
        {<EditFamilyModal
          id={family.id}
          show={editShow}
          onHide={() => setEditShow(false)}
          refetch={refetch}
        />}
        <ConfirmDeleteModal
          show={disableShow}
          onHide={() => setDisableShow(false)}
          onConfirm={handleConfirmDisable}
          title={intl.formatMessage({ id: 'CONFIRM_TEXT' })}
          message={intl.formatMessage({ id: 'BUTTON.CONFIRM_DISABLE' })}
          modalForDisable={true}
        />
        <ConfirmDeleteModal
          show={deleteShow}
          onHide={() => setDeleteShow(false)}
          onConfirm={handleConfirmDelete}
          title={intl.formatMessage({ id: 'CONFIRM_TEXT' })}
          message={intl.formatMessage({ id: 'BUTTON.CONFIRM_DELETE' })}
          modalForDisable={false}
        />
        {family.name}
        &nbsp;
        {family.isDisabled ? <span className='ms-3 badge badge-warning'>{intl.formatMessage({ id: 'LIST_FAMILIES.DISABLED' })}</span> : ''}
      </td>
      <td>
        <div className='text-end d-flex justify-content-end gap-4'>
          <span className='text-decoration-underline fw-semibold' onClick={() => setEditShow(true)}>
            {intl.formatMessage({ id: 'BUTTON.EDIT' })}
          </span>
          <span className={`text-decoration-underline fw-semibold ${family.isDisabled ? 'opacity-50' : ''}`} onClick={() => setDisableShow(true)}>
            {family.isDisabled ? intl.formatMessage({ id: 'BUTTON.ENABLE' }) : intl.formatMessage({ id: 'BUTTON.DISABLE' })}
          </span>
          <span className='text-decoration-underline fw-semibold' onClick={() => setDeleteShow(true)}>
            {intl.formatMessage({ id: 'BUTTON.DELETE' })}
          </span>
        </div>
      </td>
    </tr>
  )
}

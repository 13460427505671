import { FC, ReactNode } from "react";

interface MyComponentProps {
  title: string;
  breadcrumbItems?: string[];
  children?: ReactNode;
}

export const BreadcumbsSection: FC<MyComponentProps> = ({ title, breadcrumbItems, children }) => {
  return (<div className='d-flex justify-content-between mb-8'>
    <div>
      <h1>{title}</h1>
      <span className='text-gray-400 pt-1 fw-semibold fs-6'>
        {breadcrumbItems ? breadcrumbItems.join(' · ') : ''}
      </span>
    </div>
    {children}
  </div>)
}
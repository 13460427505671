import { Button } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'
import { requestManagementUsers } from '../../requests/requests'
import { useState } from 'react'
import { AddUserModal } from '../../components/users/AddUserModal'
import { Client } from '../../models/models'
import { SingleUser } from './SingleUser'
import {usePagination} from '../../hooks/usePagination'
import { Link } from 'react-router-dom'
import { ManagementUser } from '../../models/managementUsers'
import { configStaleTime } from '../../constants'
import { BreadcumbsSection } from '../../components/BreadcumbsSection'

export function ListUsers() {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  const {
    isLoading,
    error,
    data,
    refetch,
  } = useQuery('listUsers', () => requestManagementUsers(), {
    refetchOnWindowFocus: false,
    staleTime: configStaleTime,
  })
  
  const {
      currentItems:users,
      handlePaginationNumberClick,
      handlePreviousClick,
      handleNextClick,
      chunkedArray,
      currentPage,
    } = usePagination(data?.data as ManagementUser[], 5)

  return (
    <>
      <BreadcumbsSection title={intl.formatMessage({ id: 'LIST_USERS.TITLE' })} breadcrumbItems={[intl.formatMessage({ id: 'MENU.DASHBOARD' }), intl.formatMessage({ id: 'LIST_USERS.TITLE' })]}>
        <Button onClick={() => setShow(true)} className='my-2'>
          {intl.formatMessage({ id: 'LIST_USERS.ADD' })}
        </Button>
      </BreadcumbsSection>
      <div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
        <div className='card-body pt-0'>
          <div className='table-responsive'>
            <AddUserModal
              show={show}
              onHide={() => setShow(false)}
              refetch={refetch}
            />
            <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-175px'>
                    {intl.formatMessage({ id: 'LIST_USERS.NAME' })}
                  </th>
                  <th className='min-w-70px'>
                    {intl.formatMessage({ id: 'LIST_USERS.ROLE' })}
                  </th>
                  <th className='min-w-70px text-end'>
                    {intl.formatMessage({ id: 'LIST_USERS.ACTIONS' })}
                  </th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {isLoading && (
                  <tr>
                    <td colSpan={3}>{intl.formatMessage({ id: 'FETCH.LOADING' })}</td>
                  </tr>
                )}
                {error ? (
                  <tr>
                    <td colSpan={3}>{intl.formatMessage({ id: 'FETCH.ERROR' })}</td>
                  </tr>
                ) : (
                  false
                )}
                {
                  users && users.map((user: ManagementUser) => (
                    <SingleUser
                      user={user}
                      key={user.id}
                      refetch={refetch}
                    />
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='card card-flush py-4 flex-row-fluid overflow-hidden mt-5'>
        <div className='card-body py-0'>
            {/* PAGINATION COMPONENT */}
            <ul className='pagination'>
              <li className='page-item previous' onClick={() => handlePreviousClick()}>
                <Link to={'#'} className='page-link'>
                  <i className='previous' />
                </Link>
              </li>

              {chunkedArray?.map((_newsChunk: any, index: number) => (
                <li
                  onClick={() => handlePaginationNumberClick(index + 1)}
                  key={index}
                  className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  <Link to={'#'} className='page-link'>
                    {index + 1}
                  </Link>
                </li>
              ))}

              <li className='page-item next' onClick={() => handleNextClick()}>
                <Link to={'#'} className='page-link'>
                  <i className='next' />
                </Link>
              </li>
            </ul>
        </div>
      </div>
    </>
  )
}

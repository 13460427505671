import { useState } from 'react'
import { useIntl } from 'react-intl'
import { ConfirmDeleteModal } from '../../components/generic-confirm/Confirm'
import { EditUserModal } from '../../components/users/EditUserModal'
import { ManagementUser } from '../../models/managementUsers'
import { requestManagementUserToggleStatus } from '../../requests/requests'

export function SingleUser({
  user,
  refetch,
}: {
  user: ManagementUser
  refetch: any
}) {
  const intl = useIntl()
  const [editShow, setEditShow] = useState(false)
  const [disableShow, setDisableShow] = useState(false)

  const handleConfirmDisable = async () => {
    await requestManagementUserToggleStatus(user.id)
    refetch()
    setDisableShow(false)
  }

  return (
    <tr>
      <td>
        <EditUserModal
          user={user}
          show={editShow}
          onHide={() => setEditShow(false)}
          refetch={refetch}
        />
        <ConfirmDeleteModal
          show={disableShow}
          onHide={() => setDisableShow(false)}
          onConfirm={handleConfirmDisable}
          title={intl.formatMessage({ id: 'CONFIRM_TEXT' })}
          message={intl.formatMessage({ id: 'BUTTON.CONFIRM_DISABLE' })}
          modalForDisable={true}
        />
        <div>
          <div>
            <span className='fw-bold fs-7 text-black'>{user.firstName + ' ' + user.lastName}</span>
            &nbsp;
            {user.isDisabled && <span className='ms-3 badge badge-warning'>{intl.formatMessage({ id: 'LIST_FAMILIES.DISABLED' })}</span>}
          </div>
          <div className='fw-bold fs-7 text-muted'>{user.email}</div>
        </div>
      </td>
      <td>
        <div className='fw-bold text-gray-600'>{user.roles}</div>
      </td>
      <td>
        <div className='text-end d-flex justify-content-end gap-4'>
          <span className='text-decoration-underline fw-semibold' onClick={() => setEditShow(true)}>
            {intl.formatMessage({ id: 'BUTTON.EDIT' })}
          </span>
          <span className='text-decoration-underline fw-semibold' onClick={() => setDisableShow(true)}>
            {user.isDisabled ? intl.formatMessage({ id: 'BUTTON.ENABLE' }) : intl.formatMessage({ id: 'BUTTON.DISABLE' })}
          </span>
        </div>
      </td>
    </tr>
  )
}

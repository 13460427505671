import { Modal, Button, Form } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import { useIntl } from 'react-intl'
import { AdminInput } from '../../models/models'
import { FamilyEdit, FamilySingle } from '../../models/families'
import SuperSelect from '../SuperSelect'
import { requestFamily, requestFamilyEdit, requestProductExtensions } from '../../requests/requests'
import { useQuery } from 'react-query'
import { configStaleTime } from '../../constants'
import { ProductExtension } from '../../models/productExtensions'

function EditFamilyModal({ id, show, onHide, refetch }: { id: number, show: any, onHide: any, refetch: any }) {
  const intl = useIntl()
  const { refetch: refetchSingle, data: familySingleData } = useQuery(`singleFamily${id}`, () => requestFamily(id), {
    refetchOnWindowFocus: false,
    staleTime: configStaleTime,
  })
  const { data: productExtensionsData } = useQuery('productExtensions', () => requestProductExtensions(), {
    refetchOnWindowFocus: false,
    staleTime: configStaleTime,
  })
  const familySingle = familySingleData?.data as FamilySingle
  const productExtensions = productExtensionsData?.data as ProductExtension[]
  const addInputs = () => {
    let arr: AdminInput[] = []
    arr.push({
      uniqueId: 'familyName',
      wrapperClass: 'mb-8',
      name: `name`,
      type: 'text',
      labelKey: 'EDIT_FAMILY.NAME',
      labelName: '',
      required: true,
      initialValue: familySingle?.name,
      helperText: 'Escribe el nombre de la familia',
    })
    arr.push({
      uniqueId: 'familyProducts',
      wrapperClass: 'mb-8',
      name: `productsExtension`,
      type: 'superselect',
      labelKey: 'EDIT_FAMILY.PRODUCTS',
      labelName: '',
      required: false,
      initialValue: familySingle?.currentProductsExtension.map(x => x.id),
      helperText: 'Indica que productos estan en esta familia',
    })
    arr.push({
      uniqueId: 'familyDisabled',
      wrapperClass: 'mb-8',
      name: `isDisabled`,
      type: 'checkbox',
      labelKey: 'EDIT_FAMILY.DISABLED',
      labelName: '',
      required: false,
      initialValue: familySingle?.isDisabled,
      helperText: 'Indica si quieres desactivarlo',
      className: 'form-check-input'
    })
    return arr
  }

  const initialValues = () => {
    let initial = {}
    addInputs().forEach((el) => {
      initial[el.name] = el.initialValue
    })
    return initial
  }

  const generateInput = (el, values) => {
    if (el.conditionalShow) {
      const elementConditional = document.querySelector(el.conditionalShow);
      if (elementConditional) {
        if (!elementConditional.checked) {
          return <></>
        }
      }
    }
    return <div key={el.uniqueId} className={`${el.wrapperClass}`}>
      <Form.Group
        className={
          'd-flex flex-column'
        }
      >
        {el.type !== 'radio' && (el.labelKey.length > 0 || el.labelName.length > 0) &&
          <Form.Label className='fs-4 text-black' htmlFor={el.uniqueId}>
            {el.labelKey.length > 0 ? intl.formatMessage({ id: el.labelKey }) : el.labelName}&nbsp;{el.required ? '*' : ''}
          </Form.Label>
        }

        {el.type !== 'customfile' && el.type !== `radio` && el.type !== 'superselect' && (
          <Field
            type={el.type}
            name={el.name}
            className={
              el.className ? el.className : 'form-control bg-light border-0 text-black'
            }
            required={el.required}
            id={el.uniqueId}
            maxLength={100}
            placeholder={el.placeholder ? el.placeholder : ''}
          />
        )}

        {el.type === 'radio' && <>
          {el.showSectionLabel ? <Form.Label className='fs-4 text-black'>{el.showSectionLabel}&nbsp;{el.required ? '*' : ''}</Form.Label> : ''}

          {el.showSectionPlaceholder ? <span className="text-gray-400">{el.showSectionPlaceholder}</span> : ''}

          <div className={`form-check ${el.showSectionPlaceholder ? 'mt-5' : ''}`}>
            <Field
              type={el.type}
              name={el.name}
              className={
                el.className ? el.className : 'form-check-input mt-2'
              }
              required={el.required}
              id={el.uniqueId}
              maxLength={100}
              value={el.value}
              placeholder={el.placeholder ? el.placeholder : ''}
            />
            <Form.Label className='fs-6 text-black form-check-label ms-2' htmlFor={el.uniqueId}>
              {el.labelKey.length > 0 ? intl.formatMessage({ id: el.labelKey }) : el.labelName}&nbsp;

              <div className='text-muted'>{el.helperText}</div>

              <div>{el.childrens ? el.childrens.map((elChildren => {
                return generateInput(elChildren, values)
              })) : ''}</div>
            </Form.Label>
          </div>
        </>}

        {el.type === 'superselect' && (
          <SuperSelect
            options={productExtensions?.map((prodExt) => ({value: prodExt.id, label: `${prodExt.productNameAdmin} [${prodExt.sageCode}]`}))}
            placeholder={''}
            onChange={(optionElement) => {
              values[el.name] = optionElement.map((x) => x.value)
            }}
            isMulti={true}
            selectedOptions={familySingle.productsExtension.map((prodExtId) => ({
              value: prodExtId,
              label: productExtensions?.find((x) => x.id + '' === prodExtId + '')?.productNameAdmin,
            }))}
            required={el.required}
          ></SuperSelect>
        )}

        {el.type !== 'radio' &&
          <span className='text-gray-400 mt-2'>{el.helperText}</span>}
      </Form.Group>
    </div>
  }

  return (
    <Modal show={show} onHide={onHide} dialogClassName='modal-dialog modal-dialog-centered'>
      <Modal.Header closeButton>
        <h2 className='text-black'>
          {intl.formatMessage({ id: 'LIST_FAMILIES.EDIT_FAMILY' })}
        </h2>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={initialValues()}
          onSubmit={async (values) => {
            const edit = values as FamilyEdit
            edit.id = familySingle.id
            await requestFamilyEdit(edit)
            refetch()
            refetchSingle()
            onHide()
          }}
        >
          {({ handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              {addInputs().map((el) => (
                generateInput(el, values)
              ))}
              <Button variant='primary' type='submit' className='w-100 mt-8'>
                {intl.formatMessage({ id: 'BUTTON.EDIT' })}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EditFamilyModal

import { useIntl } from "react-intl"
import { OrderStatus, Summary, ViewOrdersModel } from "../../../models/models"
import { getProductFormat } from "../../../hooks/getProductFormat"
import { KTIcon, KTSVG } from "../../../../_metronic/helpers"
import { Link } from "react-router-dom"

export default function WrapperSummary({product, index, allOrders}:{product:Summary, index:number, allOrders:ViewOrdersModel}) {
  const intl = useIntl()
  const totalKG = (summary: Summary) => {
    const totalKg = summary?.orders?.reduce(
      (acc, currentValue) => acc + currentValue.stockRatio * currentValue.total,
      0
    )
    return totalKg
  }
    return (

        <div key={`wrapper-summary-${index}`} className='col-12 col-lg-6'>
            <div
                className='accordion-header collapsed py-3 d-flex'
                data-bs-toggle='collapse'
                data-bs-target={`#validate_accordion_item_${index}`}
            >
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                    <span>{product.productNameAdmin}</span>
                    <span> | </span>
                    <span>
                        {intl.formatMessage({ id: 'MY_ORDER_DETAILS.TABLE_HEADER_TOTAL' })}:{' '}
                        {product.total} {getProductFormat(product.packageUnit)} |{' '}
                        {totalKG(product)}{' '}
                        {product.packageUnit === 'CAJ' || product.packageUnit === 'PZA'
                            ? 'KG'
                            : 'UN'}
                    </span>
                </h3>
                <span className='accordion-icon rotate-180 ms-1'>
                    <KTSVG
                        className='svg-icon svg-icon-4'
                        path='media/icons/duotune/arrows/arr072.svg'
                    />
                </span>
            </div>
            <div
                id={`validate_accordion_item_${index}`}
                className='fs-6 collapse ps-lg-3'
                data-bs-parent={`#validate_accordion_item_${index}`}
            >
                <div
                    className='accordion accordion-icon-toggle'
                    id='validate_accordion_submenu'
                >
                    {/* LIST*/}
                    <ul className='p-0'>
                        {product?.orders?.map((order, index) => {
                            const orderObj = allOrders.orders.find(
                                (x) => x.guidId === order.orderGuidId
                            )
                            return (
                                <li
                                    key={`summary-orders-${order.orderGuidId}-${index}`}
                                    className='card d-flex flex-row justify-content-between align-items-center py-2 px-4 mb-2'
                                >
                                    <span className='w-100 w-lg-auto'>
                                        {order.total}{' '}
                                        {getProductFormat(
                                            order.packageUnit,
                                            order.stockRatio,
                                            intl.formatMessage({ id: 'GENERAL.OF' }),
                                            ''
                                        )}{' '}
                                    </span>
                                    <span className='w-100 w-lg-auto'>
                                        {order.customerBusinessName} | {order.clientBusinessName}
                                    </span>
                                    {/* VALIDATED */}
                                    {orderObj && orderObj.status === OrderStatus.Validated && (
                                        <div className='d-flex align-items-center gap-4'>
                                            <span className='badge badge-light-success'>
                                                {intl.formatMessage({
                                                    id:
                                                        'ORDER.STATUS.' +
                                                        OrderStatus[orderObj.status].toUpperCase(),
                                                })}
                                            </span>
                                        </div>
                                    )}
                                    {/* EDIT */}
                                    {orderObj && orderObj.status === OrderStatus.Pending && (
                                        <div className='d-flex align-items-center gap-4'>
                                            <Link
                                                to={`/order-edit/${order.orderGuidId}`}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                title='Edit'
                                            >
                                                <KTIcon iconName='pencil' className='fs-3' />
                                            </Link>
                                        </div>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
import { Button } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useIntl } from 'react-intl'
import { requestFamilies } from '../../requests/requests'
import { useState } from 'react'
import { usePagination } from '../../hooks/usePagination'
import { Link } from 'react-router-dom'
import { SingleFamily } from './SingleFamily'
import { AddFamilyModal } from '../../components/families/AddFamilyModal'
import { Family } from '../../models/families'
import { BreadcumbsSection } from '../../components/BreadcumbsSection'

export function ListFamilies() {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  const {
    isLoading,
    error,
    data,
    refetch,
  } = useQuery('listFamilies', () => requestFamilies(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  })

  const itemsPerPage = 5;

  const {
    currentItems: families,
    handlePaginationNumberClick,
    handlePreviousClick,
    handleNextClick,
    chunkedArray,
    currentPage,
  } = usePagination(data?.data as Family[], itemsPerPage)

  return (
    <>
      <BreadcumbsSection title={intl.formatMessage({ id: 'LIST_FAMILIES.TITLE' })} breadcrumbItems={[intl.formatMessage({ id: 'MENU.DASHBOARD' }), intl.formatMessage({ id: 'LIST_FAMILIES.TITLE' })]}>
        <Button onClick={() => setShow(true)} className='my-2'>
          {intl.formatMessage({ id: 'LIST_FAMILIES.ADD' })}
        </Button>
      </BreadcumbsSection>
      <div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
        <div className='card-body pt-0'>
          <div className='table-responsive'>
            <AddFamilyModal
              show={show}
              onHide={() => setShow(false)}
              refetch={refetch}
            />
            <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-175px'>
                    {intl.formatMessage({ id: 'LIST_FAMILIES.FAMILY' })}
                  </th>
                  <th className='min-w-70px text-end'>
                    {intl.formatMessage({ id: 'LIST_FAMILIES.ACTIONS' })}
                  </th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {!isLoading && families.length <= 0 && <> <tr>
                  <td colSpan={3}>{intl.formatMessage({ id: 'FETCH.NO_RESULTS' })}</td>
                </tr></>}
                {isLoading && (
                  <tr>
                    <td colSpan={3}>{intl.formatMessage({ id: 'FETCH.LOADING' })}</td>
                  </tr>
                )}
                {error ? (
                  <tr>
                    <td colSpan={3}>{intl.formatMessage({ id: 'FETCH.ERROR' })}</td>
                  </tr>
                ) : (
                  false
                )}
                {
                  families && families.map((family: Family) => (
                    <SingleFamily
                      family={family}
                      key={family.id}
                      refetch={refetch}
                    />
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {chunkedArray.length > 1 &&
        <div className='card card-flush py-4 flex-row-fluid overflow-hidden mt-5'>
          <div className='card-body py-0'>
            <ul className='pagination'>
              <li className='page-item previous' onClick={() => handlePreviousClick()}>
                <Link to={'#'} className='page-link'>
                  <i className='previous' />
                </Link>
              </li>
              {chunkedArray?.map((_newsChunk: any, index: number) => (
                <li
                  onClick={() => handlePaginationNumberClick(index + 1)}
                  key={index}
                  className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  <Link to={'#'} className='page-link'>
                    {index + 1}
                  </Link>
                </li>
              ))}
              <li className='page-item next' onClick={() => handleNextClick()}>
                <Link to={'#'} className='page-link'>
                  <i className='next' />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      }
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Field, useFormikContext} from 'formik'
import {useQuery, useQueryClient} from 'react-query'
import {Col, Container, Row} from 'react-bootstrap'
import {customerClients} from '../../../../requests/requests'
import {useIntl} from 'react-intl'
import {Client} from '../../../../models/models'
import {useAuth} from '../../../auth'
import {usePaginationForWrapper} from '../../../../hooks/usePaginationForWrapper'
import {useShoppingCart} from '../../../../context/ShoppingCartContext'
import {configStaleTime} from '../../../../constants'
import {PaginationWrapper} from '../../../../components/PaginationWrapper'

const StepOrder1: FC = () => {
  const [clientSearch, setClientSearch] = useState('' as string)
  const intl = useIntl()
  const {auth} = useAuth()
  const queryClient = useQueryClient()
  const formikContext = useFormikContext()
  const {clearCart} = useShoppingCart()
  const {data: users = [], isError} = useQuery<Client[]>(
    ['clients' + auth?.sageCode], // Important the 'auth.SageCode' part for identify clients for every 'distributor'
    async () =>
      customerClients().then(({data}) => {
        return data
      }),
    {staleTime: configStaleTime}
  )
  const usersPerPage = 20
  const paginationObj = usePaginationForWrapper(users, usersPerPage)
  const isCheckedClient = (client: Client) => {
    const inputCheckClient = document.getElementById('kt_order_form_client_' + client.id)
    return (
      localStorage.getItem('selectedClientID') === client.id &&
      inputCheckClient != null &&
      inputCheckClient['checked']
    )
  }

  const handleSelectClient = (client: Client) => {
    clearCart()
    localStorage.removeItem('selectedAddress_SageCode')
    queryClient.removeQueries('selectedAddress', {exact: true})
    formikContext.setFieldValue('shippingAddress', '')
    queryClient.removeQueries('selectedUser', {exact: true})
    queryClient.removeQueries('selectedDate', {exact: true})
    localStorage.removeItem('selectedExpeditionDate') // Reset para la expeditioDate
    localStorage.setItem('selectedClientID', client.id)
  }

  if (users.length <= 0) {
    if (isError) {
      return (
        <span className='alert alert-primary d-block w-100'>
          {intl.formatMessage({id: 'FETCH.NO_CLIENTS_FOUND'})}
        </span>
      )
    }
    return (
      <span className='alert alert-primary d-block w-100'>
        {intl.formatMessage({id: 'FETCH.LOADING'})}
      </span>
    )
  }

  return (
    <section className='card card-flush p-8 flex-row-fluid overflow-hidden'>
      <Container className='w-100' fluid={true}>
        <Row>
          <div className='d-flex justify-content-between align-items-center mb-8'>
            <h2>{intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP1_TITLE'})}</h2>
          </div>
        </Row>
        <Row>
          <div className='d-flex flex-column w-100 mb-8'>
            <div className='position-relative w-100'>
              <label htmlFor='' className='fs-4 fw-medium mb-4'>
                {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP1_INPUT_LABEL'})}
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3'
                id='kt_datatable_search_query'
                onChange={(e) => {
                  setClientSearch(e.target.value)
                }}
              />
              <span className='fw-normal' style={{color: '#99A1B7'}}>
                {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP1_INPUT_TEXT'})}
              </span>
            </div>
          </div>
        </Row>
        <Row>
          <ul className='d-flex justify-content-start list-unstyled flex-wrap'>
            {clientSearch ? (
              users.filter(
                (user: Client) =>
                  user.name.toLowerCase().includes(clientSearch.toLowerCase()) ||
                  user.id.includes(clientSearch)
              ).length === 0 ? (
                <p className='fs-3'>{intl.formatMessage({id: 'FETCH.NO_RESULTS'})}</p>
              ) : (
                users
                  .filter(
                    (user: Client) =>
                      user.name.toLowerCase().includes(clientSearch.toLowerCase()) ||
                      user.id.includes(clientSearch)
                  )
                  .map((filteredUser: Client) => (
                    <li
                      className='col-md-6 col-lg-3 mb-6 position-relative pe-3'
                      key={filteredUser.id}
                    >
                      <Field
                        type='radio'
                        className='position-absolute top-0'
                        style={{transform: 'translate(16px, 18px)', accentColor: '#0c2340'}}
                        name='selectedClient'
                        value={filteredUser.name}
                        id={`kt_order_form_client_${filteredUser.id.toString()}`}
                      />
                      <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center h-100 px-13 w-100'
                        htmlFor={`kt_order_form_client_${filteredUser.id.toString()}`}
                        onClick={() => {
                          handleSelectClient(filteredUser)
                        }}
                      >
                        <div className='d-flex flex-column'>
                          <h4 className='mb-0'>{filteredUser.name}</h4>
                          <span className='text-start' style={{color: '#99A1B7'}}>
                            {filteredUser.businessName}
                          </span>
                        </div>
                      </label>
                    </li>
                  ))
              )
            ) : paginationObj.currentItems.length === 0 ? (
              <p className='fs-3'>{intl.formatMessage({id: 'FETCH.NO_RESULTS'})}</p>
            ) : (
              paginationObj.currentItems.map((user: Client) => (
                <li className='col-12 col-md-6 col-lg-3 mb-6 position-relative pe-3' key={user.id}>
                  <Field
                    type='radio'
                    className='position-absolute top-0'
                    style={{transform: 'translate(16px, 18px)', accentColor: '#0c2340'}}
                    name='selectedClient'
                    value={user.name}
                    id={`kt_order_form_client_${user.id.toString()}`}
                  />
                  <label
                    className={`btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex align-items-center h-100 px-13 w-100 ${
                      isCheckedClient(user) ? 'active' : ''
                    }`}
                    htmlFor={`kt_order_form_client_${user.id.toString()}`}
                    onClick={() => {
                      handleSelectClient(user)
                    }}
                  >
                    <div className='d-block m-auto'>
                      <h4 className='mb-0'>{user.name}</h4>
                      <span className='text-start' style={{color: '#99A1B7'}}>
                        {user.businessName}
                      </span>
                    </div>
                  </label>
                </li>
              ))
            )}
          </ul>
        </Row>
        <Row>
          <Col>
            {/* PAGINATION COMPONENT */}
            {clientSearch.length <= 0 && paginationObj.chunkedArray.length > 1 && (
              <PaginationWrapper {...paginationObj} />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export {StepOrder1}

import {Col, Row, Spinner} from 'react-bootstrap'
import {LastOrdersTable} from '../../components/LastOrdersTable'
import {TopClientItem, TopClientsChart} from '../../components/TopClientsChart'
import {TopProductItem, TopProductsCard} from '../../components/TopProductsCard'
import {LastOrder} from '../../models/dashboard'
import {infoDashboard} from '../../requests/requests'
import {useQuery} from 'react-query'
import {useIntl} from 'react-intl'
import {configStaleTime} from '../../constants'
import {useAuth} from '../../modules/auth'
import { IsAdminRole } from '../../helpers/utils'

export const DashboardSummmary = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const {isLoading, error, data} = useQuery('dashboard-summary', () => infoDashboard(), {
    refetchOnWindowFocus: false,
    staleTime: configStaleTime,
  })
  if (isLoading) {
    return (
      <span className='d-flex mt-3'>
        <Spinner animation='border'></Spinner>
      </span>
    )
  }
  if (error) {
    return <span className='d-flex mt-3'>{intl.formatMessage({id: 'FETCH.ERROR'})}</span>
  }
  if (!auth || IsAdminRole(auth)) {
    return <></>
  }
  return (
    <Row className='mt-5'>
      <Col>
        <TopClientsChart className='' topClients={data?.data.topSalesClients as TopClientItem[]} />
      </Col>
      <Col>
        <TopProductsCard
          className='mt-3 mt-lg-0'
          topProducts={data?.data.topSelledProducts as TopProductItem[]}
        />
      </Col>
      <Col xs='12'>
        <LastOrdersTable className='mt-5' lastOrders={data?.data.lastOrders as LastOrder[]} />
      </Col>
    </Row>
  )
}

import { FC, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { requestDeleteOrder, requestMyLastOrders, requestMyOrders } from '../../requests/requests'
import { useAuth } from '../../modules/auth'
import { useIntl } from 'react-intl'
import { MyOrder, MyOrderLine } from '../../models/myOrders'
import { MyOrdersTable } from '../../modules/orders/MyOrdersTable'
import { configStaleTime } from '../../constants'
import { BreadcumbsSection } from '../../components/BreadcumbsSection'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { UserHasPermission } from '../../helpers/utils'
import { PagePermission } from '../../models/models'
// import { formatPrice } from '../../helpers/formatPrice';

const MyOrders: FC = () => {
  const intl = useIntl()
  const { auth } = useAuth()
  const queryClient = useQueryClient()
  const [filterDate, setFilterDate] = useState('')
  const [usingFilter, setUsingFilter] = useState(false)
  const { isFetching, refetch: refetchMyOrder } = useQuery<MyOrder[]>(
    ['myPendingOrders'],
    async () => {
      if (usingFilter) {
        const { data } = await requestMyOrders(filterDate)
        data.sort(function (a, b) {
          return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        })
        data.forEach((order: MyOrder) => {
          order.lines.forEach((line: MyOrderLine) => {
            if (line.urlImage == null) {
              line.urlImage =
                'https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg'
            }
          })
        })
        return data
      } else {
        const { data } = await requestMyLastOrders()
        data.sort(function (a, b) {
          return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        })
        data.forEach((order: MyOrder) => {
          order.lines.forEach((line: MyOrderLine) => {
            if (line.urlImage == null) {
              line.urlImage =
                'https://img.freepik.com/vector-premium/vector-icono-imagen-predeterminado-pagina-imagen-faltante-diseno-sitio-web-o-aplicacion-movil-no-hay-foto-disponible_87543-11093.jpg'
            }
          })
        })
        return data
      }
    },
    { staleTime: configStaleTime, enabled: !!auth }
  )
  const myOrders: MyOrder[] = queryClient.getQueryData(['myPendingOrders']) as MyOrder[]

  const handleRemove = async (orderId: string) => {
    await requestDeleteOrder(orderId)
    await refetchMyOrder()
  }

  useEffect(() => {
    refetchMyOrder()
  }, [refetchMyOrder, filterDate, usingFilter])

  const headerDescription = (): string => {
    if (usingFilter) {
      return `${intl.formatMessage({ id: 'GENERAL_SHOWING_ORDERS_OF' })} ${new Date(
        filterDate
      ).toLocaleDateString('en-GB')}`
    }
    return `${intl.formatMessage({ id: 'GENERAL_SHOWING_ORDERS_OF' })} ${intl.formatMessage({
      id: 'MY_ORDERS_SHOWING_LAST_90DAYS',
    })}`
  }

  return (
    <>
      <BreadcumbsSection title={intl.formatMessage({ id: 'MY_ORDERS_TITLE' })} breadcrumbItems={[intl.formatMessage({ id: 'DASHBOARD.TITLE' }), intl.formatMessage({ id: 'ORDERS.GROUPED_BY_ADDRESS.DESCRIPTION' })]}>
        {UserHasPermission(auth, PagePermission.CREATE_ORDER) && <Link to='/orders' className='my-5 mt-lg-0 d-inline-block'>
          <Button type='button' className='my-2'>
            + {intl.formatMessage({ id: 'MY_ORDERS_NEW_ORDER' })}
          </Button>
        </Link>}
      </BreadcumbsSection>
      {myOrders && (
        <MyOrdersTable
          className=''
          headerDescription={headerDescription()}
          orders={myOrders}
          headers={[
            intl.formatMessage({ id: 'GENERAL_ORDER_CREATION' }),
            intl.formatMessage({ id: 'GENERAL_CLIENT_NAME' }),
            intl.formatMessage({ id: 'GENERAL_DELIVERY_ADDRESS' }),
            intl.formatMessage({ id: 'GENERAL_DELIVERY_DATE' }),
            intl.formatMessage({ id: 'GENERAL_FINAL_IMPORT' }),
            intl.formatMessage({ id: 'GENERAL_STATUS' }),
            intl.formatMessage({ id: 'GENERAL_DELIVERY_NOTE' }),
            intl.formatMessage({ id: 'GENERAL_EXTERNAL_REFERENCE' }),
            intl.formatMessage({ id: 'GENERAL_OPTIONS' }),
          ]}
          setUsingFilter={setUsingFilter}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
          handleRemove={handleRemove}
          isFetching={isFetching}
        ></MyOrdersTable>
      )}
    </>
  )
}

export { MyOrders }

import { Client } from "../models/models"

export const useSortAddresses = (client: Client) => {
    return {
        addresses: client ? client.shippingAddresses.sort((a, b) => {
            if (a.mainAddress !== b.mainAddress) {
                return a.mainAddress ? -1 : 1
            }
            if (a.isActive !== b.isActive) {
                return a.isActive ? -1 : 1
            }
            return 0
        }) : []
    }
}

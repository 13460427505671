/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'
import { UserHasPermission } from '../../../../../app/helpers/utils'
import { PagePermission } from '../../../../../app/models/models'
//import { useQueryClient } from 'react-query'
//import { ToastifyCaller, ToastifyType } from '../../../../../app/components/Toastify'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { auth } = useAuth()
  //const queryClient = useQueryClient()

  /*const handleRefreshClick = (event) => {
    const timeOutMS = 10000;
    const button = event.currentTarget;
    const lastClick = localStorage.getItem("lastRefresh");
    const now = Date.now();
    if (lastClick && now - parseInt(lastClick, 10) < timeOutMS) {
      const pendingSeconds = ((timeOutMS/1000) - ((now - parseInt(lastClick, 10)) / 1000));
      ToastifyCaller(
        `Wait ${parseFloat(pendingSeconds.toString()).toFixed(0)}s for next manual refresh.`,
        ToastifyType.ERROR
      )
      return;
    }
    button.disabled = true;
    localStorage.setItem("lastRefresh", now.toString());
    setTimeout(() => {
      button.disabled = false;
    }, timeOutMS);
    // do the refresh
    queryClient.clear();
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('products')) {
        localStorage.removeItem(key);
      }
    });
    ToastifyCaller(
      intl.formatMessage({id: 'MENU.ISSUES_FIXED'}),
      ToastifyType.SUCCESS
    )
  };*/

  return (
    <>
      {/* DASHBOARD */}
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({ id: 'MENU.SECTIONS' })}
          </span>
        </div>
      </div>

      {/* ORDERS */}
      {(UserHasPermission(auth, PagePermission.CREATE_ORDER) || UserHasPermission(auth, PagePermission.MY_ORDERS) || UserHasPermission(auth, PagePermission.ORDERS_BY_ADDRESS) || UserHasPermission(auth, PagePermission.EXPORT_ORDERS)) && <SidebarMenuItemWithSub to='#' icon='basket' title={intl.formatMessage({ id: 'MENU.ORDERS' })}>
        {UserHasPermission(auth, PagePermission.CREATE_ORDER) &&
          <SidebarMenuItem
            to={`${UserHasPermission(auth, PagePermission.CREATE_ORDER_ADMIN) ? '/new-order' : '/orders'}`}
            title={intl.formatMessage({ id: 'MENU.CREATE_ORDER' })}
            hasBullet={true}
          />}
        {
          <>
            {UserHasPermission(auth, PagePermission.MY_ORDERS) &&
              <SidebarMenuItem
                to='/my-orders'
                title={intl.formatMessage({ id: 'MENU.LIST_MY_ORDERS' })}
                hasBullet={true}
              />}
            {UserHasPermission(auth, PagePermission.ORDERS_BY_ADDRESS) && <SidebarMenuItem
              to='/orders-by-address'
              title={intl.formatMessage({ id: 'MENU.LIST_MY_ORDERS_GROUPED_BY_ADDRESS' })}
              hasBullet={true}
            />}
            {UserHasPermission(auth, PagePermission.EXPORT_ORDERS) && <SidebarMenuItem
              to='/export-orders'
              title={intl.formatMessage({ id: 'MENU.EXPORT_ORDERS' })}
              hasBullet={true}
            />}
          </>
        }
      </SidebarMenuItemWithSub>}

      {/* NEWS */}
      <SidebarMenuItem
        to='/news'
        icon='message-text'
        title={intl.formatMessage({ id: 'MENU.NEWS' })}
        fontIcon='bi-app-indicator'
      />

      {/* ADMIN EDITS*/}
      {auth && UserHasPermission(auth, PagePermission.MANAGE_CONTENT) && (
        <SidebarMenuItemWithSub to='#' icon='gear' title='Administrar'>
          {UserHasPermission(auth, PagePermission.MANAGE_PRODUCTS) && <SidebarMenuItem
            to='/products'
            title={intl.formatMessage({ id: 'MENU.MANAGE_PRODUCTS' })}
            hasBullet={true}
          />}
          {UserHasPermission(auth, PagePermission.MANAGE_CATEGORIES) && <SidebarMenuItem
            to='/categories'
            title={intl.formatMessage({ id: 'MENU.MANAGE_CATEGORIES' })}
            hasBullet={true}
          />}
          {UserHasPermission(auth, PagePermission.MANAGE_NEWS) && <SidebarMenuItem
            to='/manage-news'
            title={intl.formatMessage({ id: 'MENU.MANAGE_NEWS' })}
            hasBullet={true}
          />}
          {UserHasPermission(auth, PagePermission.MANAGE_USERS) && <SidebarMenuItem
            to='/users'
            title={intl.formatMessage({ id: 'MENU.MANAGE_USERS' })}
            hasBullet={true}
          />}
          {/* {UserHasPermission(auth, PagePermission.MANAGE_USERS) && <SidebarMenuItem
            to='/wholesalers'
            title={intl.formatMessage({ id: 'MENU.MANAGE_USERS_WHOLESALERS' })}
            hasBullet={true}
          />} */}
          {UserHasPermission(auth, PagePermission.MANAGE_FAMILIES) && <SidebarMenuItem
            to='/families'
            title={intl.formatMessage({ id: 'MENU.MANAGE_FAMILIES' })}
            hasBullet={true}
          />}
        </SidebarMenuItemWithSub>
      )}

      {/* ADMIN ORDERS */}
      {auth && (UserHasPermission(auth, PagePermission.VALIDATE_ORDERS) || UserHasPermission(auth, PagePermission.ALL_ORDERS)) && (
        <SidebarMenuItemWithSub to='#' icon='menu' title='Gestionar Pedidos'>
          {UserHasPermission(auth, PagePermission.VALIDATE_ORDERS) &&
            <SidebarMenuItem
              to='/validate-orders'
              title={intl.formatMessage({ id: 'MENU.LIST_VALIDATE_ORDERS' })}
              hasBullet={true}
            />}
          {UserHasPermission(auth, PagePermission.ALL_ORDERS) &&
            <SidebarMenuItem
              to='/all-orders-with-filters'
              title={intl.formatMessage({ id: 'MENU.LIST_ALL_ORDERS_WITH_FILTERS' })}
              hasBullet={true}
            />}
        </SidebarMenuItemWithSub>
      )}

      {/* <div className='menu-item mt-5'>
        <div className='menu-content'>
          <span className='menu-section text-muted fs-8 ls-1'>
            <a className='text-secondary' href="#" onClick={handleRefreshClick}>{intl.formatMessage({id: 'MENU.HAVE_ISSUES'})}
            <span><KTIcon iconName='question' className='text-secondary d-inline-block ms-1'/></span></a>
          </span>
        </div>
      </div> */}
    </>
  )
}

export { SidebarMenuMain }

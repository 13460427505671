import { Modal, Button, Form } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import { useIntl } from 'react-intl'
import { AdminInput, UserRole } from '../../models/models'
import FileInput from '../../helpers/imgToBase64'
import { requestManagementUserAdd } from '../../requests/requests'
import { ManagementUserAdd } from '../../models/managementUsers'

export function AddUserModal({ show, onHide, refetch }) {
  const intl = useIntl()

  const addInputs = () => {
    let arr: AdminInput[] = []
    arr.push({
      uniqueId: 'addModalFirstName',
      wrapperClass: 'mb-8',
      name: `firstName`,
      type: 'text',
      labelKey: 'ADD_USER.FIRST_NAME',
      labelName: '',
      required: true,
      initialValue: '',
      helperText: intl.formatMessage({ id: 'ADD_USER.FIRST_NAME_PLACEHOLDER' }),
    })
    arr.push({
      uniqueId: 'addModalLastName',
      wrapperClass: 'mb-8',
      name: `lastName`,
      type: 'text',
      labelKey: 'ADD_USER.LAST_NAME',
      labelName: '',
      required: true,
      initialValue: '',
      helperText: intl.formatMessage({ id: 'ADD_USER.LAST_NAME_PLACEHOLDER' }),
    })
    arr.push({
      uniqueId: 'addModalEmail',
      wrapperClass: 'mb-8',
      name: `email`,
      type: 'email',
      labelKey: 'ADD_USER.EMAIL',
      labelName: '',
      required: true,
      initialValue: '',
      helperText: intl.formatMessage({ id: 'ADD_USER.EMAIL_PLACEHOLDER' }),
    })
    arr.push({
      uniqueId: 'addModalRoleConfronta',
      wrapperClass: '',
      name: `role`,
      type: 'radio',
      labelKey: '',
      labelName: 'Confronta',
      required: true,
      initialValue: '',
      helperText: intl.formatMessage({ id: 'ADD_USER.ROLE_CONFRONTA_HELPERTEXT' }),
      value: UserRole.Confronta+''
    })
    /*arr.push({
      uniqueId: 'addModalRoleCommercial',
      wrapperClass: '',
      name: `role`,
      type: 'radio',
      labelKey: '',
      labelName: 'Comercial',
      required: false,
      initialValue: '',
      helperText: intl.formatMessage({ id: 'ADD_USER.ROLE_COMMERCIAL_HELPERTEXT' }),
      value: UserRole.Comercial+'',
      childrens: [
        {
          uniqueId: 'sageCode',
          wrapperClass: 'mt-3',
          name: `sageCode`,
          type: 'text',
          labelKey: '',
          labelName: '',
          required: false,
          initialValue: '',
          placeholder: 'Sage Code',
          conditionalShow: '#addModalRoleCommercial'
        }
      ]
    })*/
    arr.push({
      uniqueId: 'addModalRoleMarketing',
      wrapperClass: '',
      name: `role`,
      type: 'radio',
      labelKey: '',
      labelName: 'Marketing',
      required: false,
      initialValue: '',
      helperText: intl.formatMessage({ id: 'ADD_USER.ROLE_MARKETING_HELPERTEXT' }),
      value: UserRole.Marketing+''
    })
    arr.push({
      uniqueId: 'addModalRoleBackOffice',
      wrapperClass: '',
      name: `role`,
      type: 'radio',
      labelKey: '',
      labelName: 'Back Office',
      required: false,
      initialValue: '',
      helperText: intl.formatMessage({ id: 'ADD_USER.ROLE_BACKOFFICE_HELPERTEXT' }),
      value: UserRole.BackOffice+''
    })
    arr.push({
      uniqueId: 'addModalRoleTIC',
      wrapperClass: '',
      name: `role`,
      type: 'radio',
      labelKey: '',
      labelName: 'TIC',
      required: false,
      initialValue: '',
      helperText: intl.formatMessage({ id: 'ADD_USER.ROLE_TIC_HELPERTEXT' }),
      value: UserRole.TIC+''
    })
    return arr
  }

  const initialValues = () => {
    let initial = {}
    addInputs().forEach((el) => {
      initial[el.name] = el.initialValue
    })
    return initial
  }

  const generateInput = (el) => {
    if (el.conditionalShow) {
      setInterval(() => {
        const elementConditional = document.querySelector(el.conditionalShow);
        if (elementConditional) {
          const hideElementSelector = '#' + el.uniqueId;
          if (!elementConditional.checked) {
            const elementToHide = document.querySelector(hideElementSelector);
            if (elementToHide) {
              elementToHide.classList.add('opacity-0', 'position-absolute')
            }
          } else {
            const elementToHide = document.querySelector(hideElementSelector);
            if (elementToHide) {
              elementToHide.classList.remove('opacity-0', 'position-absolute')
            }
          }
        }
      }, 500)
    }
    return <div key={el.uniqueId} className={`${el.wrapperClass}`}>
      <Form.Group
        className={
          'd-flex flex-column'
        }
      >
        {el.type !== 'radio' && (el.labelKey.length > 0 || el.labelName.length > 0) &&
          <Form.Label className='fs-4 text-black' htmlFor={el.uniqueId}>
            {el.labelKey.length > 0 ? intl.formatMessage({ id: el.labelKey }) : el.labelName}&nbsp;{el.required ? '*' : ''}
          </Form.Label>
        }

        {el.type === 'customfile' && <FileInput />}

        {el.type !== 'customfile' && el.type !== `radio` && (
          <Field
            type={el.type}
            name={el.name}
            className={
              el.className ? el.className : 'form-control bg-light border-0 text-black'
            }
            required={el.required}
            id={el.uniqueId}
            maxLength={100}
            placeholder={el.placeholder ? el.placeholder : ''}
            disabled={el.disabled}
          />
        )}

        {el.type === 'radio' && <>
          {el.showSectionLabel ? <Form.Label className='fs-4 text-black'>{el.showSectionLabel}&nbsp;{el.required ? '*' : ''}</Form.Label> : ''}

          {el.showSectionPlaceholder ? <div className="text-gray-400">{el.showSectionPlaceholder}</div> : ''}

          <div className={`form-check ${el.showSectionPlaceholder ? 'mt-5' : ''}`}>
            <Field
              type={el.type}
              name={el.name}
              className={
                el.className ? el.className : 'form-check-input mt-2'
              }
              required={el.required}
              id={el.uniqueId}
              maxLength={100}
              value={el.value}
              placeholder={el.placeholder ? el.placeholder : ''}
            />
            <Form.Label className='fs-6 text-black form-check-label ms-2' htmlFor={el.uniqueId}>
              {el.labelKey.length > 0 ? intl.formatMessage({ id: el.labelKey }) : el.labelName}&nbsp;

              <div className='text-muted'>{el.helperText}</div>

              <div>{el.childrens ? el.childrens.map((elChildren => {
                return generateInput(elChildren)
              })) : ''}</div>
            </Form.Label>
          </div>
        </>}

        {el.type !== 'radio' &&
          <span className='text-gray-400 mt-2'>{el.helperText}</span>}
      </Form.Group>
    </div>
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className='text-black'>
            {intl.formatMessage({ id: 'LIST_USERS.ADD_USER' })}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={initialValues()}
          onSubmit={async(values:any) => {
            values.role = parseInt(values.role)
            const userAdd = values as ManagementUserAdd
            await requestManagementUserAdd(userAdd)
            refetch()
            onHide()
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              {addInputs().map((el) => (
                generateInput(el)
              ))}
              <div className='d-flex justify-content-end'>
                <Button
                  type='reset'
                  className='btn btn-secondary btm-sm mt-8  me-4'
                  onClick={onHide}
                >
                  {intl.formatMessage({ id: 'BUTTON.CANCEL' })}
                </Button>
                <Button variant='primary' type='submit' className='btn btn-primary btm-sm mt-8'>
                  {intl.formatMessage({ id: 'BUTTON.ADD' })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

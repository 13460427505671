import { useQuery } from 'react-query'
import { DefaultExpeditionDate } from '../models/models'
import { requestExpeditionDate } from '../requests/requests'
import { useState } from 'react'
import { IsAdminRole } from '../helpers/utils'
import { useAuth } from '../modules/auth'

export const useMinExpeditionDate = () => {
  const { auth } = useAuth()
  const { refetch: refetchMinDate } = useQuery<DefaultExpeditionDate>(
    ['deliveryDateAdmin'],
    async () => {
      const { data: x } = await requestExpeditionDate()
      if (IsAdminRole(auth)) {
        setMinExpeditionDate(new Date('01-01-2000'))
      } else {
        setMinExpeditionDate(new Date(x.expeditionDate))
      }
      const maxDate = new Date(x.expeditionDate)
      maxDate.setHours(24 * 30)
      setMaxExpeditionDate(maxDate)
      return x
    },
    { staleTime: 60 * 60 * 24 * 365 }
  )
  const [minExpeditionDate, setMinExpeditionDate] = useState<Date>(new Date())
  const [maxExpeditionDate, setMaxExpeditionDate] = useState<Date>(new Date())
  return [minExpeditionDate, maxExpeditionDate, refetchMinDate]
}

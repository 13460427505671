import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { useAuth } from '../../../../../app/modules/auth'
import { UserHasPermission } from '../../../../../app/helpers/utils'
import { PagePermission } from '../../../../../app/models/models'

export function MenuInner() {
  const intl = useIntl()
  const { auth } = useAuth()

  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.COMMANDS'})} to='/orders' /> */}

      {UserHasPermission(auth, PagePermission.CREATE_ORDER) || UserHasPermission(auth, PagePermission.MY_ORDERS) || UserHasPermission(auth, PagePermission.ORDERS_BY_ADDRESS) || UserHasPermission(auth, PagePermission.EXPORT_ORDERS) && <MenuInnerWithSub
        title={intl.formatMessage({ id: 'MENU.COMMANDS' })}
        to={`${UserHasPermission(auth, PagePermission.CREATE_ORDER_ADMIN) ? '/new-order' : UserHasPermission(auth, PagePermission.CREATE_ORDER) ? '/orders' : ''}`}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        {UserHasPermission(auth, PagePermission.CREATE_ORDER) &&
          <MenuItem
            to={`${UserHasPermission(auth, PagePermission.CREATE_ORDER_ADMIN) ? '/new-order' : '/orders'}`}
            title={intl.formatMessage({ id: 'MENU.CREATE_ORDER' })}
            hasBullet={true}
          />}
        {
          <>
            {UserHasPermission(auth, PagePermission.MY_ORDERS) && <MenuItem
              to='/my-orders'
              title={intl.formatMessage({ id: 'MENU.LIST_MY_ORDERS' })}
              hasBullet={true}
            />}
            {UserHasPermission(auth, PagePermission.ORDERS_BY_ADDRESS) && <MenuItem
              to='/orders-by-address'
              title={intl.formatMessage({ id: 'MENU.LIST_MY_ORDERS_GROUPED_BY_ADDRESS' })}
              hasBullet={true}
            />}
            {UserHasPermission(auth, PagePermission.EXPORT_ORDERS) && <MenuItem
              to='/export-orders'
              title={intl.formatMessage({ id: 'MENU.EXPORT_ORDERS' })}
              hasBullet={true}
            />}
          </>
        }
      </MenuInnerWithSub>}
      <MenuItem title={intl.formatMessage({ id: 'MENU.NEWS' })} to='/news' />

      {/* ADMIN EDITS*/}
      {auth && UserHasPermission(auth, PagePermission.MANAGE_CONTENT) && (
        <MenuInnerWithSub
          to='#'
          title='Administrar'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {UserHasPermission(auth, PagePermission.MANAGE_PRODUCTS) && <MenuItem
            to='/products'
            title={intl.formatMessage({ id: 'MENU.MANAGE_PRODUCTS' })}
            hasBullet={true}
          />}
          {UserHasPermission(auth, PagePermission.MANAGE_CATEGORIES) && <MenuItem
            to='/categories'
            title={intl.formatMessage({ id: 'MENU.MANAGE_CATEGORIES' })}
            hasBullet={true}
          />}
          {UserHasPermission(auth, PagePermission.MANAGE_NEWS) && <MenuItem
            to='/manage-news'
            title={intl.formatMessage({ id: 'MENU.MANAGE_NEWS' })}
            hasBullet={true}
          />}
          {UserHasPermission(auth, PagePermission.MANAGE_USERS) && <MenuItem
            to='/users'
            title={intl.formatMessage({ id: 'MENU.MANAGE_USERS' })}
            hasBullet={true}
          />}
          {/* {UserHasPermission(auth, PagePermission.MANAGE_USERS) && <MenuItem
            to='/wholesalers'
            title={intl.formatMessage({ id: 'MENU.MANAGE_USERS_WHOLESALERS' })}
            hasBullet={true}
          />} */}
          {UserHasPermission(auth, PagePermission.MANAGE_FAMILIES) && <MenuItem
            to='/families'
            title={intl.formatMessage({ id: 'MENU.MANAGE_FAMILIES' })}
            hasBullet={true}
          />}
        </MenuInnerWithSub>
      )}

      {/* ADMIN ORDERS */}
      {auth && (UserHasPermission(auth, PagePermission.VALIDATE_ORDERS) || UserHasPermission(auth, PagePermission.ALL_ORDERS)) && (
        <MenuInnerWithSub
          to='#'
          title='Gestionar pedidos'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {UserHasPermission(auth, PagePermission.VALIDATE_ORDERS) && <MenuItem
            to='/validate-orders'
            title={intl.formatMessage({ id: 'MENU.LIST_VALIDATE_ORDERS' })}
            hasBullet={true}
          />}
          {UserHasPermission(auth, PagePermission.ALL_ORDERS) && <MenuItem
            to='/all-orders-with-filters'
            title={intl.formatMessage({ id: 'MENU.LIST_ALL_ORDERS_WITH_FILTERS' })}
            hasBullet={true}
          />}
        </MenuInnerWithSub>
      )}
    </>
  )
}

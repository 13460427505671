import {Modal, Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'

interface ConfirmationModalProps {
  show: boolean
  onHide: () => void
  onConfirm: () => void
  title: string
  message: string
  areDeleting?: boolean
  type?: string
  modalForDisable?: boolean
}

export const ConfirmDeleteModal: React.FC<ConfirmationModalProps> = ({
  show,
  onHide,
  onConfirm,
  title,
  message,
  areDeleting = false,
  type = 'error',
  modalForDisable = false
}) => {
  const intl = useIntl()

  return (
    <>
      <Modal show={show} onHide={onHide} dialogClassName='modal-dialog modal-dialog-centered'>
        <div
          className={`alert alert-dismissible ${
            type === 'error' ? 'bg-light-danger' : ''
          } d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-0`}
        >
          {type === 'error' && (
            <span className='svg-icon svg-icon-5tx svg-icon-danger mb-5'>
              <i className='bi bi-x-circle text-danger fs-4x'></i>
            </span>
          )}

          <div className='text-center'>
            <h5 className='fw-bolder fs-1 mb-5'>{title}</h5>

            <div className='separator separator-dashed border-danger opacity-25 mb-5'></div>

            <div className='mb-9 fs-4'>{message}</div>
            <div className='d-flex flex-center flex-wrap'>
              <Button
                variant='danger'
                data-toggle='tooltip'
                title={modalForDisable ? intl.formatMessage({id: 'BUTTON.YES_DISABLE'}) : intl.formatMessage({id: 'BUTTON.YES_DELETE'})}
                onClick={onConfirm}
                className={`btn btn-danger m-2 ${areDeleting ? 'disabled' : ''}`}
              >
                { modalForDisable ? intl.formatMessage({id: 'BUTTON.YES_DISABLE'}) : intl.formatMessage({id: 'BUTTON.YES_DELETE'})}
              </Button>
              <Button
                variant='secondary'
                data-toggle='tooltip'
                title={intl.formatMessage({id: 'BUTTON.NO_CANCEL'})}
                onClick={onHide}
                className={`btn btn-outline btn-outline-danger btn-active-danger m-2 ${
                  areDeleting ? 'disabled' : ''
                }`}
              >
                {intl.formatMessage({id: 'BUTTON.NO_CANCEL'})}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export const formatDate = (d: string, options?:Intl.DateTimeFormatOptions) => {
  return new Date(d).toLocaleDateString('en-GB', options)
}
export const formatDateType = (d: Date, format?: string) => {
  return new Date(d).toLocaleDateString(format ? format : 'en-GB')
}

export const inputDateFormat = (d: string | Date) => {
  var dObj = new Date(d)
  var mm = (dObj.getMonth() + 1).toString().padStart(2, '0')
  var dd = dObj.getDate()
  var yyyy = dObj.getFullYear()
  return yyyy + '-' + mm + '-' + dd
}

export const formattedDateTime:Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
}

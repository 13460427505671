import { useIntl } from 'react-intl'
import { CustomerWithID } from '../../models/allOrdersWithFilters'
import { formatDate } from '../../helpers/formatDate'

export function SingleWholesaler({
  user,
}: {
  user: CustomerWithID
}) {
  const intl = useIntl()

  return (
    <tr>
      <td>
        <div>
          <div>
            <span className='fw-bold fs-7 text-black'>{user.businessName}</span>
          </div>
          <div className='fw-bold fs-7 text-muted'>{user.sageCode}</div>
        </div>
      </td>
      <td>
        {user.email}
      </td>
      <td>
        {user.dateCreated ? formatDate(user.dateCreated.toString(), {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          minute: "2-digit",
          hour: "2-digit"
        }) : ''}
      </td>
      <td>
        {user.dateUpdated ? formatDate(user.dateUpdated.toString(), {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          minute: "2-digit",
          hour: "2-digit"
        }) : ''}
      </td>
      <td>
        <div className='fw-bold text-gray-600'>{user.lastLogin ? (new Date(user.lastLogin).getFullYear() <= 1 ? intl.formatMessage({ id: 'LIST_USERS.NEVER' }) : user.lastLogin.toString()) : intl.formatMessage({ id: 'LIST_USERS.NEVER' })}</div>
      </td>
      <td>
        {user.lastPurchase ? (new Date(user.lastLogin).getFullYear() <= 1 ? intl.formatMessage({ id: 'LIST_USERS.NEVER' }) : formatDate(user.lastPurchase.toString(), {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          minute: "2-digit",
          hour: "2-digit"
        })) : ''}
      </td>
    </tr>
  )
}

import { FC, useState } from 'react'
import { Field } from 'formik'
import { useQuery, useQueryClient } from 'react-query'
import { Col, Row } from 'react-bootstrap'
import { Client, DefaultExpeditionDate, ShippingAddress } from '../../../../models/models'
import { customerAddress, requestExpeditionDate } from '../../../../requests/requests'
import { useIntl } from 'react-intl'
import { IsAdminRole } from '../../../../helpers/utils'
import { useAuth } from '../../../auth'
import { configStaleTime } from '../../../../constants'

const StepOrder3: FC = () => {
  const { auth } = useAuth()
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [minDeliveryDate, setMinDeliveryDate] = useState<Date>(new Date())
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem('selectedExpeditionDate')
      ? localStorage.getItem('selectedExpeditionDate')
      : new Date(minDeliveryDate).toISOString().slice(0, 10)
  )
  const selectedAddress: ShippingAddress = queryClient.getQueryData(
    'selectedAddress'
  ) as ShippingAddress

  useQuery<Client[]>(
    ['selectedUser'],
    () => {
      return customerAddress(localStorage.getItem('selectedClientID') as string).then(
        ({ data: x }) => {
          return x
        }
      )
    },
    { staleTime: configStaleTime, enabled: !!localStorage.getItem('selectedClientID') }
  )

  const { refetch: refetchMinDate, data: minExpeditionDate } = useQuery<DefaultExpeditionDate>(
    ['deliveryDate'],
    async () => {
      const { data: x } = await requestExpeditionDate()
      setSelectedDate(x.expeditionDate)
      setMinDeliveryDate(new Date(x.expeditionDate))
      return x
    },
    { staleTime: configStaleTime }
  )
  const selectedDateQuery: string = queryClient.getQueryData('selectedDate') as string

  if (selectedAddress && !selectedDateQuery) {
    refetchMinDate()
    queryClient.setQueryData(['selectedDate'], minExpeditionDate?.expeditionDate)
  }

  const handleDateChange = (e: any) => {
    const newValue = e.target.value
    const newDate = new Date(newValue)
    if (newDate.getDay() === 6 || newDate.getDay() === 0) return
    if (newDate >= minDeliveryDate) {
      queryClient.setQueryData(['selectedDate'], newValue)
      setSelectedDate(newValue)
      localStorage.setItem('selectedExpeditionDate', newValue)
    }
  }
  const minDate = () => {
    if (IsAdminRole(auth)) {
      return new Date().toISOString().slice(0, 10)
    }
    return minDeliveryDate.toISOString().slice(0, 10)
  }
  const maxDate = () => {
    const minDate = new Date(minDeliveryDate.toISOString().slice(0, 10))
    minDate.setHours(24 * 30)
    return minDate.toISOString().slice(0, 10)
  }
  return (
    <>
      <Row className='gap-0'>
        <Col lg={6} className='h-100'>
          <section className='card card-flush p-8 flex-row-fluid overflow-hidden h-100'>
            {/* CALENDAR */}
            <Row>
              <h2 className='text-dark mb-8'>
                {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_STEP3_TITLE_DATE' })}
              </h2>
            </Row>
            <Row className='mb-12'>
              <div className='w-100 w-lg-25'>
                <label className='mb-2' style={{ color: '#99A1B7' }} htmlFor=''>
                  {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_STEP3_SUBTITLE_DATE' })}
                </label>
                <Field
                  className='form-control'
                  type='date'
                  name='selectedDate'
                  id='deliveryDate'
                  onChange={(e: any) => handleDateChange(e)}
                  value={selectedDate}
                  min={minDate()}
                  max={maxDate()}
                />
              </div>
            </Row>
            <Row>
              <label htmlFor='deliveryDate' className='text-dark form-label'>
                <p className='mb-0'>
                  {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_STEP2_SUBTITLE_DATE' })}
                </p>
                <p className='mb-0'>
                  {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_STEP2_SUBTITLE_DATE_PART2' })}
                </p>
              </label>
              <p className='mb-0'>
                {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_STEP2_SUBTITLE_DATE_PART3' })}
              </p>
              <p className='mb-0'>
                {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_STEP2_SUBTITLE_DATE_PART4' })}
              </p>
            </Row>
          </section>
        </Col>
        <Col lg={6} className='mt-3 mt-lg-0 h-100'>
          <section className='card card-flush p-8 flex-row-fluid overflow-hidden h-100'>
            <Row>
              <h2 className='text-dark mb-8'>
                {intl.formatMessage({ id: 'GENERAL_EXTERNAL_REFERENCE' })}
              </h2>
            </Row>
            <Row className='mb-12'>
              <div className='w-100'>
                <label className='mb-2' style={{ color: '#99A1B7' }} htmlFor=''>
                  {intl.formatMessage({ id: 'ORDERS.CREATE_NEW_ORDER_STEP3_REFERENCE_HELP_TEXT' })}
                </label>
                <Field
                  className='form-control mt-15 w-lg-50'
                  type='text'
                  name='reference'
                  id='customerExternalReference'
                  placeholder={intl.formatMessage({ id: 'GENERAL_EXTERNAL_REFERENCE' })}
                  onChange={(e) => {
                    queryClient.setQueryData(['customerExternalReference'], e.target.value)
                  }}
                  maxLength={20}
                />
              </div>
            </Row>
          </section>
        </Col>
      </Row>
    </>
  )
}

export { StepOrder3 }

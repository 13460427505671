/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import DashboardNews from './DashboardNews'
import { DashboardSummmary } from './DashboardSummary'
import { BreadcumbsSection } from '../../components/BreadcumbsSection'

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <BreadcumbsSection title={intl.formatMessage({ id: 'DASHBOARD.TITLE' })}/>
      <DashboardNews />
      <DashboardSummmary/>
    </>
  )
}

export {DashboardWrapper}

import { Modal, Button, Form } from 'react-bootstrap'
import { Formik, Field } from 'formik'
import { useIntl } from 'react-intl'
import { AdminInput } from '../../models/models'
import { FamilyAdd } from '../../models/families'
import { requestFamilyAdd } from '../../requests/requests'

export function AddFamilyModal({ show, onHide, refetch }) {
  const intl = useIntl()

  const addInputs = () => {
    let arr: AdminInput[] = []
    arr.push({
      uniqueId: 'familyName',
      wrapperClass: 'mb-8',
      name: `name`,
      type: 'text',
      labelKey: '',
      labelName: 'Nombre de familia',
      required: true,
      initialValue: '',
      helperText: 'Escribe el nombre de la familia',
    })
    return arr
  }

  const initialValues = () => {
    let initial = { }
    addInputs().forEach((el) => {
      initial[el.name] = el.initialValue
    })
    return initial
  }

  const generateInput = (el) => {
    if (el.conditionalShow) {
      const elementConditional = document.querySelector(el.conditionalShow);
      if (elementConditional) {
        if (!elementConditional.checked) {
          return <></>
        }
      }
    }
    return <div key={el.uniqueId} className={`${el.wrapperClass}`}>
      <Form.Group
        className={
          'd-flex flex-column'
        }
      >
        {el.type !== 'radio' && (el.labelKey.length > 0 || el.labelName.length > 0) &&
        <Form.Label className='fs-4 text-black' htmlFor={el.uniqueId}>
          {el.labelKey.length > 0 ? intl.formatMessage({ id: el.labelKey }) : el.labelName}&nbsp;{el.required ? '*' : ''}
        </Form.Label>
        }

        {el.type !== 'customfile' && el.type !== `radio` && (
          <Field
            type={el.type}
            name={el.name}
            className={
              el.className ? el.className : 'form-control bg-light border-0 text-black'
            }
            required={el.required}
            id={el.uniqueId}
            maxLength={100}
            placeholder={el.placeholder ? el.placeholder : ''}
          />
        )}

        {el.type === 'radio' && <>
          {el.showSectionLabel ? <Form.Label className='fs-4 text-black'>{el.showSectionLabel}&nbsp;{el.required ? '*' : ''}</Form.Label> : ''}

          {el.showSectionPlaceholder ? <span className="text-gray-400">{el.showSectionPlaceholder}</span> : ''}

          <div className={`form-check ${el.showSectionPlaceholder ? 'mt-5' : ''}`}>
            <Field
              type={el.type}
              name={el.name}
              className={
                el.className ? el.className : 'form-check-input mt-2'
              }
              required={el.required}
              id={el.uniqueId}
              maxLength={100}
              value={el.value}
              placeholder={el.placeholder ? el.placeholder : ''}
            />
            <Form.Label className='fs-6 text-black form-check-label ms-2' htmlFor={el.uniqueId}>
              {el.labelKey.length > 0 ? intl.formatMessage({ id: el.labelKey }) : el.labelName}&nbsp;

              <div className='text-muted'>{el.helperText}</div>

              <div>{el.childrens ? el.childrens.map((elChildren => {
                return generateInput(elChildren)
              })) : ''}</div>
            </Form.Label>
          </div>
        </>}

        {el.type !== 'radio' &&
          <span className='text-gray-400 mt-2'>{el.helperText}</span>}
      </Form.Group>
    </div>
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className='text-black'>
            {intl.formatMessage({ id: 'LIST_FAMILIES.ADD_FAMILY' })}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={initialValues()}
          onSubmit={async (values) => {
            await requestFamilyAdd(values as FamilyAdd)
            refetch()
            onHide()
          }}
        >
          {({handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              {addInputs().map((el) => (
                generateInput(el)
              ))}
              <div className='d-flex justify-content-end'>
                <Button
                  type='reset'
                  className='btn btn-secondary btm-sm mt-8  me-4'
                  onClick={onHide}
                >
                  {intl.formatMessage({ id: 'BUTTON.CANCEL' })}
                </Button>
                <Button variant='primary' type='submit' className='btn btn-primary btm-sm mt-8'>
                  {intl.formatMessage({ id: 'BUTTON.ADD' })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

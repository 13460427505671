import { Row } from "react-bootstrap"
import { useIntl } from "react-intl"
import { GroupedByFamily } from "../../../models/models"

const GroupedByFamilySection = ({ groupedByFamilies }: { groupedByFamilies: GroupedByFamily[] }) => {
    const intl = useIntl()

    return (
        <section className='card mb-6 pb-3'>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3'>
                        <h3>{intl.formatMessage({ id: 'VALIDATE_ORDERS.GROUPED_BY_FAMILY_TITLE' })}</h3>
                    </span>
                </h3>
            </div>
            <div className='card-body py-0 pb-5'>
                <div className='accordion accordion-icon-toggle' id='validate_accordion'>
                    <Row>
                        {groupedByFamilies &&
                            groupedByFamilies.map((item: GroupedByFamily, index) => {
                                return (
                                    <div key={`wrapper-grouped-by-family-${index}`} className='col-12 col-lg-3 mb-5 mb-lg-0'>
                                        <div className='p-5 border border-1'>
                                            <div className='text-dark fw-bold d-block fs-6'>{item.familyName}</div>
                                            <div>
                                                {item.formats.map((x, index) => {
                                                    return `${x.value} ${x.key} ${index == item.formats.length - 1 ? '' : '& '}`
                                                })}
                                            </div>
                                            <div style={{visibility: item.totalKG > 0 ? 'visible' : 'hidden'}}>{item.totalKG}KG</div>
                                        </div>
                                    </div>
                                )
                            })}
                    </Row>
                </div>
            </div>
        </section>)
}

export default GroupedByFamilySection
import {FC, useEffect, useState} from 'react'
import {StoreItems} from './functions/storeItems'
import {useIntl} from 'react-intl'
import {Category, Product} from '../../../../models/models'
import {usePagination} from '../../../../hooks/usePagination'
import {Link} from 'react-router-dom'
import InlineShoppingCart from '../inlineShoppingCart'
import {useQuery} from 'react-query'
import {requestCategories} from '../../../../requests/requests'
import {Alert, Col, Row} from 'react-bootstrap'
import {useAuth} from '../../../auth'
import {useProductsAvailablesForSelectedClient} from '../../../../hooks/useProductsAvailablesForSelectedClient'
import { useSelectedExpeditionDate } from '../../../../hooks/useSelectedExpeditionDate'
import { IsAdminRole } from '../../../../helpers/utils'

const StepOrder4: FC = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const [productSearch, setProductSearch] = useState('' as string)
  const [filterByCategory, setFilterByCategory] = useState('' as string)
  const selectedExpeditionDate = useSelectedExpeditionDate();
  const {availableProducts, selectedClient} = useProductsAvailablesForSelectedClient(selectedExpeditionDate)

  const availableProductsUnique = () => {
    const products = [] as Product[]
    const alreadyShowed = [] as string[]
    availableProducts &&
      availableProducts.map((product) => {
        if (!alreadyShowed.includes(product.code)) {
          if (productSearch.length > 0) {
            const searchOK =
              product.code.includes(productSearch) ||
              product.description.toLowerCase().indexOf(productSearch.toLocaleLowerCase()) >= 0
            if (searchOK) {
              alreadyShowed.push(product.code)
              products.push(product)
            }
          } else {
            alreadyShowed.push(product.code)
            products.push(product)
          }
        }
        return products
      })
    return products
  }

  const {data: categoriesData} = useQuery<Category[]>(
    ['All Categories'],
    async () => requestCategories().then(({data}) => data),
    {staleTime: 60 * 60 * 24 * 365, enabled: !!IsAdminRole(auth)}
  )

  const {
    currentItems,
    handlePaginationNumberClick,
    handlePreviousClick,
    handleNextClick,
    chunkedArray,
    refreshPagination,
    currentPage,
  } = usePagination(availableProductsUnique(), 10, productSearch)

  const currentItemsWithCatFilter = availableProductsUnique() as Product[]

  const similarProducts = (product: Product): Product[] => {
    const similarProductsOrdered = [...availableProducts]
    similarProductsOrdered.sort((a, b) => {
      return a.stockRatio - b.stockRatio
    })
    return similarProductsOrdered.filter((x) => x.code === product.code)
  }

  // Refresh pagination if selected client or have a change in currentItems
  useEffect(() => {
    refreshPagination()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient && chunkedArray && chunkedArray.length])

  return (
    <section className='card card-flush p-8 flex-row-fluid overflow-hidden'>
      <div className='container-fluid'>
        <div>
          <h2 className='text-dark mb-8'>
            {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP4_TITLE'})}
          </h2>
        </div>

        <InlineShoppingCart />

        <Row>
          <Col>
            <div className='d-flex flex-column w-100 mb-8'>
              <Row>
                {/* INPUT SEARCH */}
                <div className='position-relative col-lg-7'>
                  <label htmlFor='' className='fs-4 mb-4 fw-normal'>
                    {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP4_INPUT_LABEL'})}
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3'
                    id='kt_datatable_search_query'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                      }
                    }}
                    onChange={(e) => {
                      setProductSearch(e.target.value)
                      handlePaginationNumberClick(1)
                    }}
                  />
                </div>
                {/* SELECT CATEGORY */}
                <div className='col-lg-4 offset-lg-1'>
                  <Row className='align-items-center justify-content-start h-100'>
                    <Col xs='12'>
                      <div className='d-flex'>
                        <div className='d-flex flex-wrap gap-2'>
                          {categoriesData &&
                            categoriesData.map((category) => {
                              return (
                                <Link
                                  to={'#'}
                                  // cuado se seeleccione una categoría, los resultados se han de filtrar y la misma categoría del filtro ha de cambiar la clase "badge-secondary" por "badge-primary
                                  className={`badge ${
                                    filterByCategory === category.id
                                      ? 'badge-primary'
                                      : 'badge-secondary'
                                  }`}
                                  key={category.id}
                                  onClick={() => {
                                    if (filterByCategory === category.id) {
                                      setFilterByCategory('')
                                    } else {
                                      setFilterByCategory(category.id)
                                    }
                                  }}
                                >
                                  {category.name}
                                </Link>
                              )
                            })}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <span style={{color: '#99A1B7'}}>
                  {intl.formatMessage({id: 'ORDERS.CREATE_NEW_ORDER_STEP4_INPUT_TEXT'})}
                </span>
              </Row>
            </div>
          </Col>
        </Row>

        {currentItems.length <= 0 && (
          <>
            <Row>
              <Col>
                <Alert variant='primary'>
                  {intl.formatMessage({id: 'FETCH.NO_PRODUCTS_FOUND'})}
                </Alert>
              </Col>
            </Row>
          </>
        )}
        {currentItems.length > 0 && (
          <>
            <div className='d-flex flex-column w-100 mb-8 gap-1'>
              <div
                className='w-100'
                style={{height: 'auto', overflow: 'auto', overflowX: 'hidden'}}
              >
                {filterByCategory &&
                  currentItemsWithCatFilter &&
                  currentItemsWithCatFilter
                    .filter((x) => x.categories.find((x) => x.id === filterByCategory))
                    .map((product) => {
                      return StoreItems(product, similarProducts(product))
                    })}
                {!filterByCategory &&
                  currentItems &&
                  currentItems.map((product) => {
                    return StoreItems(product, similarProducts(product))
                  })}
              </div>
            </div>

            {/* PAGINATION COMPONENT */}
            {!filterByCategory && (
              <ul className='pagination'>
                <li className='page-item previous' onClick={() => handlePreviousClick()}>
                  <Link to={'#'} className='page-link'>
                    <i className='previous' />
                  </Link>
                </li>

                {chunkedArray?.map((_newsChunk: any, index: number) => (
                  <li
                    onClick={() => handlePaginationNumberClick(index + 1)}
                    key={index}
                    className={`page-item ${
                      productSearch.length > 0 && index === 0 && currentPage === 1
                        ? 'active-search'
                        : ''
                    } ${currentPage === index + 1 ? 'active' : ''}`}
                  >
                    <Link to={'#'} className='page-link'>
                      {index + 1}
                    </Link>
                  </li>
                ))}

                <li className='page-item next' onClick={() => handleNextClick()}>
                  <Link to={'#'} className='page-link'>
                    <i className='next' />
                  </Link>
                </li>
              </ul>
            )}
          </>
        )}
      </div>
    </section>
  )
}

export {StepOrder4}

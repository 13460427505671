import {useRef, useState} from 'react'
import {ViewOrdersModel} from '../models/models'
import {useQuery, useQueryClient} from 'react-query'
import {CustomerWithID} from '../models/allOrdersWithFilters'
import {requestAllDistributors} from '../requests/requests'
import {useAuth} from '../modules/auth'
import {ToNormalizedState} from '../helpers/utils'
import {useSaveFilters} from './useSaveFilters'
import {SaveFilterForcedValue} from '../helpers/filterForcedValue'

// Custom hook for filter orders
export function useSortOrdersToValidate() {
  const {auth} = useAuth()
  const queryClient = useQueryClient()
  const filterByClientRef = useRef();
  const allOrders: ViewOrdersModel = queryClient.getQueryData([
    'ordersToValidate',
  ]) as ViewOrdersModel
  const [filteredUser, setFilteredUser] = useState('')
  const [filteredStatus, setFilteredStatus] = useState('')
  const {saveFilter} = useSaveFilters()
  const handleChangeSelectedUser = (selected, ref) => {
    filterByClientRef.current = ref.current;
    if (selected) {
      setFilteredUser(selected.value)
      saveFilter('filteredUser', selected.value)
    } else {
      setFilteredUser('')
      saveFilter('filteredUser', '')
    }
  }
  const allOrdersFiltered = (): ViewOrdersModel => {
    let resultOrders = allOrders
    if (filteredStatus.length > 0 && resultOrders) {
      const parsedToNumber = parseInt(filteredStatus)
      const isState = isNaN(parsedToNumber)
      const filteredByStatus = structuredClone(resultOrders)
      filteredByStatus.orders = resultOrders.orders.filter((x) => {
        if (isState) {
          return ToNormalizedState(x.sageOrderStatus) === ToNormalizedState(filteredStatus)
        } else {
          return x.status === parsedToNumber
        }
      })
      resultOrders = filteredByStatus
      SaveFilterForcedValue(filteredStatus, 'forcedValueStatus')
    }
    if (filteredUser && resultOrders) {
      const allOrdersSorted = structuredClone(resultOrders)
      allOrdersSorted.orders = resultOrders.orders.filter((x) => x.customer.id === filteredUser)
      SaveFilterForcedValue(filteredUser, 'forcedValueClient')
      return allOrdersSorted
    }
    return resultOrders
  }
  useQuery<CustomerWithID[]>(
    ['allDistributors'],
    async () => {
      return requestAllDistributors().then(({data: x}) => {
        return x
      })
    },
    {staleTime: 60 * 60 * 24 * 365, enabled: !!auth}
  )
  const customers: CustomerWithID[] = queryClient.getQueryData([
    'allDistributors',
  ]) as CustomerWithID[]
  const customerOptions = () => {
    if (!customers) {
      return {}
    }
    return [...customers].map((element) => ({
      value: element.id,
      label: `${element.businessName}`,
    }))
  }
  return {
    allOrdersFiltered,
    customerOptions,
    filteredUser,
    setFilteredUser,
    handleChangeSelectedUser,
    filteredStatus,
    setFilteredStatus,
    filterByClientRef
  }
}

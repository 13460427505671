import {setLanguage} from '../../../../_metronic/i18n/Metronici18n'
import {ToastifyCaller, ToastifyType} from '../../../components/Toastify'
import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const authModelJson = JSON.parse(lsValue);
    const auth: AuthModel = authModelJson as AuthModel
    if (auth) {
      //You can easily check auth_token expiration also
      if (typeof auth.language != 'undefined') {
        setLanguage(auth?.language.toLocaleLowerCase())
      }
      const nowDate = new Date()
      const limitDate = new Date(auth.expiration)
      // Check in every navigation if need reload for do the login again if have expired credentials
      if (nowDate > limitDate) {
        removeAuth()
        window.location.reload()
      }
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

let globalErrorsShowed = 0
window['showToastOnError'] = true

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      const errorMessageFromAPI: string = error.response?.data?.message
      if (globalErrorsShowed < 3 && window['showToastOnError']) {
        // Max 3 toastify calls
        ToastifyCaller(
          errorMessageFromAPI ? errorMessageFromAPI : error.message,
          ToastifyType.ERROR
        )
        globalErrorsShowed++
      }
      return Promise.reject(error)
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}

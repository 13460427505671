import { useQuery } from 'react-query'
import { requestPublicNews } from '../../requests/requests'
import { useIntl } from 'react-intl'
import DashboardSingleNew from './PublicSingleNews'
import { Row } from 'react-bootstrap'
import { usePaginationForWrapper } from '../../hooks/usePaginationForWrapper'
import { PaginationWrapper } from '../../components/PaginationWrapper'
import { BreadcumbsSection } from '../../components/BreadcumbsSection'

export function News() {
  const intl = useIntl()
  const newsPerPage = 6

  const getTimeOfDate = (date?: Date) => {
    return date != null ? new Date(date).getTime() : 0
  }

  const { isFetching, error, data } = useQuery('dashboardNews', () => requestPublicNews(), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  })

  const newsData =
    data &&
    data.data &&
    data.data.sort((a, b) => {
      return getTimeOfDate(b.dateCreated) - getTimeOfDate(a.dateCreated)
    })

  const paginationObj = usePaginationForWrapper(newsData, newsPerPage)

  if (!paginationObj.currentItems) return <></>

  return (
    <>
      <BreadcumbsSection title={intl.formatMessage({ id: 'NEWS.NEWS_TITLE' })} breadcrumbItems={[intl.formatMessage({ id: 'MENU.DASHBOARD' }), intl.formatMessage({ id: 'NEWS.NEWS_TITLE' })]}>
      </BreadcumbsSection>
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 me-9 mt-4'>
        <section className='card-body p-lg-20'>

          <Row className='gx-10 gy-10'>
            {isFetching ? (
              <p>{intl.formatMessage({ id: 'FETCH.LOADING' })}</p>
            ) : error ? (
              <p>{intl.formatMessage({ id: 'FETCH.ERROR' })}</p>
            ) : (
              paginationObj.currentItems?.map((article: any) => (
                <DashboardSingleNew
                  id={article.id}
                  title={article.title}
                  content={article.description}
                  image={article.urlImage}
                  date={article.dateCreated}
                  externalLink={article.externalLink}
                  key={article.id}
                />
              ))
            )}
          </Row>
        </section>

        {/* PAGINATION */}
        <section className='mb-8'>
          <PaginationWrapper {...paginationObj} />
        </section>
      </div>
    </>
  )
}

import { FC, useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { confirmOrders, requestAllDistributors } from '../../requests/requests'
import { OrderStatus, PendingOrder, Summary, ViewOrdersModel } from '../../models/models'
import { useLocation } from 'react-router-dom'
import ValidateOrdersRow from './components/ValidateOrdersRow'
import { useIntl } from 'react-intl'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import SuperSelect from '../../components/SuperSelect'
import { useSortOrdersToValidate } from '../../hooks/useSortOrdersToValidate'
import { useNavigateHelper } from '../../hooks/useNavigateHelper'
import { useQueryValidateOrders } from '../../hooks/useQueryValidateOrders'
import { FilterByDate } from '../../components/FilterByDate'
import { formatDate, formatDateType } from '../../helpers/formatDate'
import { FilterByStatus } from '../../components/FilterByStatus'
import { useSaveFilters } from '../../hooks/useSaveFilters'
import { CustomerWithID } from '../../models/allOrdersWithFilters'
import { ToNormalizedState } from '../../helpers/utils'
import { SaveFilterForcedValue } from '../../helpers/filterForcedValue'
import { defaultDateForValidateOrders } from '../../constants'
import GroupedByFamilySection from './components/GroupedByFamilySection'
import { BreadcumbsSection } from '../../components/BreadcumbsSection'
import WrapperSummary from './components/WrapperSummary'

const ViewAllOrders: FC = () => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const filterByStateRef = useRef();
  const [selectedOrders, setSelectedOrders] = useState<string[]>([])
  const [recoveredFilter, setRecoveredFilter] = useState<boolean>(false)
  const { saveFilter, getSavedFilter, existSavedFilter, removeSavedFilter } = useSaveFilters()
  const navigate = useNavigateHelper()
  const location = useLocation()
  const [filteredDate, setFilteredDate] = useState(
    localStorage.getItem('filteredDate')
      ? new Date(localStorage.getItem('filteredDate') as string)
      : new Date()
  )
  const [validatingOrder, setValidatingOrder] = useState<boolean>(false)
  const [validatingIndividualOrder, setValidatingIndividualOrder] = useState<string>('')
  const { isFetchingValidateOrders, refetchValidateOrders } = useQueryValidateOrders(filteredDate)
  useQuery<CustomerWithID[]>(
    ['allDistributors'],
    async () => {
      return requestAllDistributors().then(({ data: x }) => {
        return x
      })
    },
    { staleTime: 60 * 60 * 24 * 365 }
  )
  const allUsers: CustomerWithID[] = queryClient.getQueryData([
    'allDistributors',
  ]) as CustomerWithID[]
  const allOrders: ViewOrdersModel = queryClient.getQueryData([
    'ordersToValidate',
  ]) as ViewOrdersModel
  let orderStates = [{}]
  if (allOrders) {
    // test
    // allOrders.orders.filter((x) => {
    //   if (x.status === 1) {
    //     x.state = 'Received'
    //   }
    //   return x
    // })
    // Get a state for filters
    const orderGroupByState = allOrders.orders?.filter((value, index, array) => {
      return (
        array.findIndex((x) => x.sageOrderStatus === value.sageOrderStatus) === index &&
        value.sageOrderStatus != null
      )
    })
    orderStates = orderGroupByState.map((x) => ({
      value: x.sageOrderStatus,
      label: x.sageOrderStatus,
    }))
  }
  const {
    allOrdersFiltered,
    customerOptions,
    setFilteredUser,
    filteredUser,
    handleChangeSelectedUser,
    filteredStatus,
    setFilteredStatus,
    filterByClientRef
  } = useSortOrdersToValidate()

  const anyFilteredStatusValid =
    (typeof filteredStatus === 'number' && filteredStatus >= 0) ||
    typeof filteredStatus === 'string'
  const filteredStatusLbl = isNaN(parseInt(filteredStatus) as number)
    ? ToNormalizedState(filteredStatus as string)
    : OrderStatus[filteredStatus as string]
  const currentFilteredUser = (): CustomerWithID => {
    if (!allUsers) return {} as CustomerWithID
    const customer: CustomerWithID | undefined = allUsers.find((x) => x.id === filteredUser)
    if (customer) {
      SaveFilterForcedValue(filteredUser, 'forcedValueClient')
      return customer
    }
    return {} as CustomerWithID
  }
  const handleChangeChecked = () => {
    const elTableValidateOrders = document.getElementById('table_validate_orders')
    if (elTableValidateOrders) {
      const elChecks = elTableValidateOrders.querySelectorAll(
        '.form-check-input:not(.form-check-input-manager)'
      ) as NodeListOf<HTMLInputElement>
      const elChecksManager = elTableValidateOrders.querySelector(
        '.form-check-input-manager'
      ) as HTMLInputElement
      if (elChecksManager) {
        elChecks.forEach((el) => {
          el.checked = elChecksManager.checked
        })
        setOrdersSelection()
      }
    }
  }
  const setOrdersSelection = () => {
    setSelectedOrders([])
    const elTableValidateOrders = document.getElementById('table_validate_orders')
    if (elTableValidateOrders) {
      const elChecks = elTableValidateOrders.querySelectorAll(
        '.form-check-input:not(.form-check-input-manager)'
      ) as NodeListOf<HTMLInputElement>
      let newSelectedOrders: string[] = []
      elChecks.forEach((el) => {
        const orderIdToValidateEl = el.closest('tr[data-id]')
        if (orderIdToValidateEl && el.checked) {
          const orderIdToValidate = orderIdToValidateEl.getAttribute('data-id') as string
          newSelectedOrders.push(orderIdToValidate)
        }
      })
      setSelectedOrders(newSelectedOrders)
    }
  }
  const validateSelectedOrders = async () => {
    setValidatingOrder(true)
    const confirmedOrders = await confirmOrders(selectedOrders)
    setValidatingOrder(false)
    localStorage.setItem('ordersValidated', JSON.stringify(confirmedOrders.data))
    await refetchValidateOrders()
    navigate(location.pathname)
  }
  const allIsValidated = (summaryObj: Summary) => {
    let allValidated = true
    for (let x = 0; x < summaryObj.orders.length; x++) {
      const orderObj = summaryObj.orders[x]
      const orderObjX = allOrders.orders.find((x) => x.guidId === orderObj.orderGuidId)
      if (orderObjX && orderObjX.status === OrderStatus.Pending) {
        allValidated = false
      }
    }
    return allValidated
  }
  const noNeedCheckSelect = () => {
    return allOrders && allOrders.summary.every((x) => allIsValidated(x))
  }
  // The valid method for run refetch every filteredDate change
  useEffect(() => {
    if (isNaN(filteredDate as any)) {
      localStorage.setItem('filteredDate', new Date().toISOString().split('T')[0])
    } else {
      localStorage.setItem('filteredDate', filteredDate.toISOString().split('T')[0])
    }
    refetchValidateOrders()
  }, [filteredDate, refetchValidateOrders])
  useEffect(() => {
    if (!recoveredFilter) {
      setRecoveredFilter(true)
      if (existSavedFilter('filteredUser')) {
        const filtered = getSavedFilter('filteredUser')
        setFilteredUser(filtered)
      }
      if (existSavedFilter('filteredStatus')) {
        const filtered = getSavedFilter('filteredStatus')
        setFilteredStatus(filtered)
      }
      if (existSavedFilter('filteredExpeditionDate')) {
        const filtered = new Date(getSavedFilter('filteredExpeditionDate'))
        setFilteredDate(filtered)
      }
    }
  }, [
    existSavedFilter,
    getSavedFilter,
    recoveredFilter,
    setFilteredDate,
    setFilteredStatus,
    setFilteredUser,
  ])
  return (
    <>
      <BreadcumbsSection title={intl.formatMessage({ id: 'MENU.LIST_VALIDATE_ORDERS' })} breadcrumbItems={[intl.formatMessage({ id: 'MENU.DASHBOARD' }), intl.formatMessage({ id: 'MENU.LIST_VALIDATE_ORDERS' })]} />
      <section className='card mb-6 pb-3'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({ id: 'VALIDATE_ORDERS.FILTER_BY_EXPEDITION_DATE' })}
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {intl.formatMessage({ id: 'GENERAL_SHOWING_ORDERS_OF' })}{' '}
              {formatDate(filteredDate.toDateString())}
            </span>
          </h3>
        </div>
        <div className='card-body'>
          <FilterByDate
            onSubmit={async (values) => {
              if (values['selectedDate'].length <= 0) {
                values['selectedDate'] = defaultDateForValidateOrders.toISOString().split('T')[0];
              }
              setFilteredDate(new Date(values['selectedDate']))
            }}
            filteredDate={filteredDate}
            setFilteredDate={setFilteredDate}
          />
        </div>
      </section>
      <GroupedByFamilySection groupedByFamilies={allOrders && allOrders.groupedByFamilies} />
      {/* ACCORDION */}
      <section className='card mb-6 pb-3'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3'>
              <h3>{intl.formatMessage({ id: 'VALIDATE_ORDERS.TITLE' })}</h3>
            </span>
          </h3>
        </div>
        <div className='card-body py-0'>
          {isFetchingValidateOrders && <span>{intl.formatMessage({ id: 'FETCH.LOADING' })}</span>}

          <div className='accordion accordion-icon-toggle' id='validate_accordion'>
            {/* {allOrders && allOrders.summary.every((x) => allIsValidated(x)) && (
              <span>{intl.formatMessage({id: 'FETCH.NO_RESULTS'})}</span>
            )} */}

            <Row>
              {allOrders &&
                allOrders?.summary?.map((product: Summary, index) => {
                  return <WrapperSummary allOrders={allOrders} product={product} index={index} key={'wrapperSummaryOrders' + index}></WrapperSummary>
                })}
            </Row>
          </div>
        </div>
      </section>
      {/* VALIDATION TABLE */}
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({ id: 'VALIDATE_ORDERS.TABLE_TITLE' })}
            </span>
            {(filteredDate || anyFilteredStatusValid || filteredUser.length > 0) && (
              <span className='fw-semibold fs-6 alert alert-primary mt-5'>
                {intl.formatMessage({ id: 'GENERAL_SHOWING_ORDERS_OF' })}{' '}
                {filteredUser.length > 0 && <>{currentFilteredUser().businessName}</>}
                {filteredUser.length <= 0 && <>{intl.formatMessage({ id: 'GENERAL_ALL_CLIENTS' })}</>}
                {filteredStatus && anyFilteredStatusValid && filteredStatusLbl && (
                  <span>
                    &nbsp;[
                    {intl.formatMessage({
                      id: 'ORDER.STATUS.' + filteredStatusLbl.toLocaleUpperCase(),
                    })}
                    ]
                  </span>
                )}
                {filteredDate && <>&nbsp; - {formatDateType(filteredDate)}</>}
              </span>
            )}
          </h3>

          <Row className='justify-content-start align-items-center w-100'>
            <Col lg='auto'>
              <FilterByStatus
                translator={intl}
                handleChangeSelected={(x, ref) => {
                  filterByStateRef.current = ref.current;
                  if (x) {
                    saveFilter('filteredStatus', x.value)
                    setFilteredStatus(x.value)
                  } else {
                    setFilteredStatus('')
                    removeSavedFilter('filteredStatus')
                  }
                }}
                sageStatusInFilter={orderStates}
              />
            </Col>
            <Col>
              <div className='card-toolbar justify-content-start'>
                <div className='w-100 w-lg-auto'>
                  <SuperSelect
                    options={customerOptions()}
                    onChange={handleChangeSelectedUser}
                    placeholder={intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_FILTER_BY_WHOLESALER' })}
                    selectedOptions={null}
                    forceValueByLocalStorageName='forcedValueClient'
                    isClearable={true}
                  ></SuperSelect>
                </div>
                {(filteredUser || filteredStatus.length > 0) && (
                  <Button
                    variant='secondary'
                    type='button'
                    className='ms-lg-3 mt-5 mt-lg-0'
                    onClick={() => {
                      setFilteredUser('')
                      removeSavedFilter('filteredUser')
                      setFilteredStatus('')
                      removeSavedFilter('filteredStatus')
                      if (filterByClientRef && filterByClientRef.current) {
                        (filterByClientRef.current as any).clearValue()
                      }
                      if (filterByStateRef && filterByStateRef.current) {
                        (filterByStateRef.current as any).clearValue()
                      }
                    }}
                  >
                    {intl.formatMessage({ id: 'MY_ORDERS_FILTER_CLEAR' })}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {selectedOrders && selectedOrders.length > 0 && (
            <div className={`d-flex justify-content-end align-items-center`}>
              <Button
                variant='primary'
                onClick={validateSelectedOrders}
                className={`d-flex align-items-center ${validatingOrder ? 'disabled' : ''}`}
              >
                {intl.formatMessage({ id: 'VALIDATE_ORDERS.ROW_VALIDATE_SELECTED' })}
                {validatingOrder && <Spinner animation='border' className='mx-3'></Spinner>}
              </Button>
            </div>
          )}
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              id='table_validate_orders'
            >
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='w-25px'>
                    {!noNeedCheckSelect() && (
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input form-check-input-manager'
                          type='checkbox'
                          onChange={handleChangeChecked}
                        />
                      </div>
                    )}
                  </th>
                  <th className='min-w-150px text-center'>
                    {intl.formatMessage({ id: 'VALIDATE_ORDERS.HEADER_ORDER_CREATED_DATE' })}
                  </th>
                  <th className='min-w-150px text-center'>
                    {intl.formatMessage({ id: 'VALIDATE_ORDERS.HEADER_ORDER_CREATED_BY' })}
                  </th>
                  <th className='min-w-120px text-center'>
                    {intl.formatMessage({ id: 'GENERAL_DELIVERY_ADDRESS' })}
                  </th>
                  <th className='min-w-120px text-center'>
                    {intl.formatMessage({ id: 'VALIDATE_ORDERS.HEADER_ORDER_DELIVERY_DATE' })}
                  </th>
                  <th className='min-w-120px text-center'>
                    {intl.formatMessage({ id: 'GENERAL_STATUS' })}
                  </th>
                  <th className='min-w-30px max-w-50 text-center'>
                    {intl.formatMessage({ id: 'VALIDATE_ORDERS.HEADER_ORDER_DETAIL' })}
                  </th>
                  <th className='min-w-100px text-end'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {allOrdersFiltered &&
                  allOrdersFiltered()?.orders?.map((order: PendingOrder) => {
                    return (
                      <ValidateOrdersRow
                        refetch={refetchValidateOrders}
                        order={order}
                        key={`validate-orders-row-${order.guidId}`}
                        setOrdersSelection={setOrdersSelection}
                        validatingOrder={validatingOrder}
                        setValidatingOrder={setValidatingOrder}
                        validatingIndividualOrder={validatingIndividualOrder}
                        setValidatingIndividualOrder={setValidatingIndividualOrder}
                      ></ValidateOrdersRow>
                    )
                  })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export { ViewAllOrders }

import { ToastifyCaller, ToastifyType } from '../components/Toastify'
import { InvoicePackingListResponse, Order } from '../models/allOrdersWithFilters'
import { Line, PagePermission, UserPermission, UserRole } from '../models/models'
import { MyOrder } from '../models/myOrders'
import { AuthModel } from '../modules/auth'
import { orderInvoice, orderPackagingList } from '../requests/requests'
import { pdfWindowLauncher } from './pdfWindowLauncher'

export const generateInvoicePDF = async (
  setGeneratingPDF: React.Dispatch<React.SetStateAction<number>>,
  setGeneratingOrderPDF: React.Dispatch<React.SetStateAction<string>>,
  myOrder: MyOrder | Order,
  invoiceId: string
) => {
  setGeneratingPDF(1)
  setGeneratingOrderPDF(invoiceId)
  try {
    const response = await orderInvoice(myOrder.guidId, invoiceId)
    setGeneratingPDF(0)
    setGeneratingOrderPDF('')
    if (response.data.haveErrors) {
      ToastifyCaller(response.data.errorMessage, ToastifyType.ERROR)
    } else {
      const invoiceResponse = response.data as InvoicePackingListResponse
      pdfWindowLauncher(invoiceResponse.base64)
    }
  } catch (Exception) {
    setGeneratingPDF(0)
    setGeneratingOrderPDF('')
  }
}

export const generatePackagingListPDF = async (
  setGeneratingPDF: React.Dispatch<React.SetStateAction<number>>,
  setGeneratingOrderPDF: React.Dispatch<React.SetStateAction<string>>,
  myOrder: MyOrder | Order,
  invoiceId: string
) => {
  setGeneratingPDF(2)
  setGeneratingOrderPDF(invoiceId)
  try {
    const response = await orderPackagingList(myOrder.guidId, invoiceId)
    setGeneratingPDF(0)
    setGeneratingOrderPDF('')
    if (response.data.haveErrors) {
      ToastifyCaller(response.data.errorMessage, ToastifyType.ERROR)
    } else {
      const invoiceResponse = response.data as InvoicePackingListResponse
      pdfWindowLauncher(invoiceResponse.base64)
    }
  } catch (Exception) {
    setGeneratingPDF(0)
    setGeneratingOrderPDF('')
  }
}

export const generateKeyFromString = (str) => {
  return str
    .toUpperCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-zA-Z0-9_]/g, '')
}

export const PasswordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/gm
export const ShowPackagingListOrInvoiceInOrderList = false

export const ToNormalizedState = (state: string) => {
  if (state === 'Facturado') {
    state = 'Invoiced'
  }
  if (state === 'Enviado') {
    state = 'Sent'
  }
  if (state === 'Recibido') {
    state = 'Received'
  }
  if (state === 'Procesando') {
    state = 'Processing'
  }
  return state
}

export const IsFrozenProduct = (productCode:string) => {
  return productCode.startsWith('ACA') || productCode.startsWith('ACAB')
}

export const IsSmallPieceProduct = (line:Line) => {
  return line.stockRatio < 100
}

export const IsPieceProduct = (productCode:string) => {
  return productCode === 'AFABC0003' || productCode === 'AFABC0002' || productCode === 'AFABC0004'
}

export const GetLoggedUserRole = (user: AuthModel) => {
  // The first time in login that have a str value from api, with that fix that issue
  return user.roles.map(x => typeof x === "number" ? UserRole[x] : x).join(' | ');
}

export const GetLoggedUserName = (user: AuthModel) => {
  return user.businessName ? user.businessName : user.customerName
}

export const ParseUserRolesFromStrings = (roles: string[]): UserRole[] => {
  return roles
    .map((role) => UserRole[role as keyof typeof UserRole])
    .filter((role): role is UserRole => role !== undefined);
}

export const DefaultPermissions: UserPermission[] = [
  // Confronta: Usuario con permisos para ver pedidos. No tiene permisos de edición
  // Comercial: Usuario con permisos para ver pedidos propios y pedidos procedentes de SAGE
  // Marketing: Usuario con permisos para publicar noticias y productos
  // BackOffice: User with all permissions like 'SuperAdmin',
  // TIC: Igual que backoffice pero tiene tambien acceso a gestion de usuarios
  {
    Role: UserRole.Mayorista,
    Permissions: [
      PagePermission.MY_ORDERS,
      PagePermission.CREATE_ORDER,
      PagePermission.ORDERS_BY_ADDRESS,
      PagePermission.EXPORT_ORDERS
    ]
  },
  {
    Role: UserRole.Confronta,
    Permissions: [
      PagePermission.ALL_ORDERS,
    ]
  },
  {
    Role: UserRole.Comercial,
    Permissions: [
      PagePermission.MY_ORDERS,
      PagePermission.ORDERS_BY_ADDRESS,
      PagePermission.EXPORT_ORDERS
    ]
  },
  {
    Role: UserRole.Marketing,
    Permissions: [
      PagePermission.MANAGE_CONTENT,
      PagePermission.MANAGE_PRODUCTS,
      PagePermission.MANAGE_CATEGORIES,
      PagePermission.MANAGE_NEWS,
      PagePermission.MANAGE_FAMILIES
    ]
  },
  {
    Role: UserRole.BackOffice,
    Permissions: [
      PagePermission.MANAGE_CONTENT,
      PagePermission.CREATE_ORDER,
      PagePermission.CREATE_ORDER_ADMIN,
      PagePermission.MANAGE_PRODUCTS,
      PagePermission.MANAGE_CATEGORIES,
      PagePermission.MANAGE_NEWS,
      PagePermission.MANAGE_FAMILIES,
      PagePermission.VALIDATE_ORDERS,
      PagePermission.ALL_ORDERS
    ]
  },
  {
    Role: UserRole.TIC,
    //Permissions: Object.keys(PagePermission).map(x => PagePermission[x])
    Permissions: [
      PagePermission.MANAGE_CONTENT,
      PagePermission.CREATE_ORDER,
      PagePermission.CREATE_ORDER_ADMIN,
      PagePermission.MANAGE_PRODUCTS,
      PagePermission.MANAGE_CATEGORIES,
      PagePermission.MANAGE_NEWS,
      PagePermission.MANAGE_USERS,
      PagePermission.MANAGE_FAMILIES,
      PagePermission.VALIDATE_ORDERS,
      PagePermission.ALL_ORDERS
    ]
  }
]

export const GetPermission = (user: AuthModel) => {
  const obj = DefaultPermissions.find(x => user.userRoles.includes(x.Role))
  return obj;
}

export const UserHasPermission = (user: AuthModel, permission: PagePermission): boolean => {
  return GetPermission(user)?.Permissions?.includes(permission)
}

export const IsAdminRole = (user: AuthModel):boolean => {
  return user?.userRoles.includes(UserRole.BackOffice) || user?.userRoles.includes(UserRole.TIC);
}
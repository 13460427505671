import { ProductExtension } from "./productExtensions"

export enum Language {
  English,
  Spanish,
}

export interface Client {
  id: string
  businessName: string
  name: string
  tin: string
  postalCode: string
  thirdPartyInvoice: string
  commercialDiscount: number
  shippingAddresses: ShippingAddress[]
  currency: string
  productsCategory: string
  productsZone: string
  documentationUri: string
}

export interface ShippingAddress {
  sageCode: string
  clientId: string
  description: string
  countryName: string
  countryCode: string
  postalCode: string
  city: string
  address: string
  mainAddress: boolean
  isActive: boolean
}

export interface Product {
  code: string
  category: string
  zone: string
  description: string
  stockUnit: string
  salesUnit: string
  viewRatio: string
  packageUnit: string
  stockRatio: number
  zMin: number
  zMax: number
  zRpta: null
  splitPieceQuestion: null
  urlImage: string
  promotionalText: string
  inPromotion: boolean
  categories: Category[]
  netPricePerUnit: number
}

export interface Category {
  id: string
  name: string
}

export interface DefaultExpeditionDate {
  expeditionDate: string
}

// Model for the cart
export type CartItem = {
  code: string
  stockRatio: number
  quantity: number
  packageUnit: string
  netPricePerUnit: number
  splitPieceQuestion: boolean
  completeOrder: boolean
}

// Models for get prices from api
export interface CartPrice {
  clientId: string
  shippingAddressSageCode: string
  lines: CartLine[]
  expeditionDate: string
}

export interface CartLine {
  code: string
  packageUnit: string
  stockRatio: number
  quantity: number
  splitPieceQuestion: boolean
}

export interface CartPriceResponse {
  clientId: string
  shippingAddressSageCode: any
  lines: CartLineResponse[]
  expeditionDate: string
  amount: number
  finalAmount: number
  comercialDiscount: number
}

export interface CartLineResponse {
  code: string
  packageUnit: string
  stockRatio: number
  quantity: number
  netPrice: number
  grossPrice: number
  netPricePerUnit: number
  grossPricePerUnit: number
  discountValue1: number
  discountValue2: number
  splitPieceQuestion: boolean // This attr is extra, not comming from api response
  completeOrder: boolean // This attr is extra, not comming from api response
}

// Models for send order to the API
export interface OrderWithDetail {
  clientId: string
  detail: OrderDetail
  lines: OrderLine[]
  expeditionDate: string
}

export interface OrderDetail {
  shippingAddressSageCode: string
}

export interface OrderLine {
  code: string
  packageUnit: string
  stockRatio: number
  quantity: number
  completeOrder: boolean
  splitPieceQuestion: boolean
}

// Models for get all orders (Admin)
export interface ViewOrdersModel {
  orders: PendingOrder[]
  summary: Summary[]
  groupedByFamilies: GroupedByFamily[]
}

export interface GroupedByFamily {
  formats:    Format[]
  familyName: string
  totalKG:    number
}

export interface Format {
  key:   string;
  value: number;
}

export interface PendingOrder {
  guidId: string
  customer: Customer
  clientId: string
  detail: Detail
  lines: Line[]
  amount: number
  finalAmount: number
  dateCreated: string
  expeditionDate: string
  status: number
  sageOrderStatus: string
  sageResponse: string
}

export interface Customer {
  id: string
  firstName: string
  lastName: string
  businessName: string
  email: string
}

export interface Detail {
  clientBusinessName: string
  clientPostalCode: string
  comercialDiscount: number
  shippingAddressSageCode: string
  shippingAddressDescription: string
  shippingAddressCountryName: string
  shippingAddressCountryCode: string
  shippingAddressPostalCode: string
  shippingAddressCity: string
  shippingAddress: string
}

export interface Line {
  quantity: number
  grossPrice: number
  netPrice: number
  code: string
  category: string
  zone: string
  description: string
  stockUnit: string
  salesUnit: string
  viewRatio: string
  packageUnit: string
  stockRatio: number
  zMin: number
  zMax: number
  splitPieceQuestion: boolean
  completeOrder: boolean
}

export interface Summary {
  sageCode: string
  productNameAdmin: string
  stockUnit: string
  packageUnit: string
  total: number
  orders: Order[]
}

export interface Order {
  orderGuidId: string
  customerName: string
  clientBusinessName: string
  customerBusinessName: string
  stockUnit: string
  stockRatio: number
  packageUnit: string
  total: number
}

// Order status
export enum OrderStatus {
  Pending,
  Validated,
}

// Models for news
export interface NewsModel {
  id: number
  title: string
  description: string
  urlImage: string
  enable: true
  lang: 0
  dateCreated: Date
  externalLink: string
}

export interface PublicNewsModel {
  id: number
  title: string
  description: string
  urlImage: string
  dateCreated: Date
  externalLink: string
}

export interface AddNewsModel {
  title: string
  description: string
  imageBs64?: string
  enable: boolean
  lang: number
  externalLink: string
}

export interface EditNewsModel {
  id: number
  title: string
  description: string
  urlImage?: string
  imageBs64: string
  enable: boolean
  lang: number
  externalLink: string
}

// Models for categories
export interface AddCategoryModel {
  name: CategoryName[]
}

export interface EditCategoryModel {
  id: number
  name: CategoryName[]
}

export interface CategoryName {
  value: string
  language: Language
}

export interface CategoryWithLanguages {
  id: number
  name: CategoryName[]
}

// Profile
export interface UserProfile {
  returnMessage: string
  success: boolean
  id: string
  email: string
  firstName: string
  businessName: string
  lastName: string
  phoneNumber: string
  sageCode: string
}

// Validate orders response
export interface ValidateOrdersResponse {
  key: string
  value: boolean
}

// New interfaces with inputs for admin
export interface AdminInput {
  uniqueId: string
  wrapperClass: string
  name: string
  type: String
  labelKey: string
  labelName: string
  required: boolean
  initialValue: string | number | boolean | number[]
  helperText?: string
  placeholder?: string
  className?: string
  showSectionLabel?: string
  showSectionPlaceholder?: string
  value?: string,
  childrens?: AdminInput[]
  conditionalShow?: string // Show if element matched by that selector is checked (Only implemented for radio button using conditionals)
  disabled?: boolean
}

// User Roles Enum
export enum UserRole {
  Confronta = 0,
  Comercial = 1,
  Marketing = 2,
  BackOffice = 3,
  TIC = 4,
  Mayorista = 100, // Is special role, not come with all of normal roles
}

// Page permissions
export enum PagePermission {
  MANAGE_CONTENT,
  CREATE_ORDER,
  CREATE_ORDER_ADMIN,
  MY_ORDERS,
  ORDERS_BY_ADDRESS,
  EXPORT_ORDERS,
  MANAGE_PRODUCTS,
  MANAGE_CATEGORIES,
  MANAGE_NEWS,
  MANAGE_USERS,
  MANAGE_FAMILIES,
  VALIDATE_ORDERS,
  ALL_ORDERS,
}

export interface UserPermission {
  Role: UserRole,
  Permissions: PagePermission[]
}
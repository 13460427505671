import {GetFilterForcedValue} from '../helpers/filterForcedValue'
import {ToNormalizedState} from '../helpers/utils'
import {OrderStatus} from '../models/models'
import SuperSelect from './SuperSelect'

export const FilterByStatus = ({
  handleChangeSelected,
  translator,
  children,
  sageStatusInFilter,
}: {
  handleChangeSelected: any
  translator: any
  children?: any
  sageStatusInFilter?: any
}) => {
  const getStatus = () => {
    let statusLabels = [] as string[]
    for (const status in OrderStatus) {
      if (!isNaN(Number(status))) {
        statusLabels.push(status)
      }
    }
    return statusLabels
  }
  const status = [...getStatus()].map((element) => ({
    value: element,
    label: translator.formatMessage({id: `ORDER.STATUS.${OrderStatus[element].toUpperCase()}`}),
  }))
  if (sageStatusInFilter) {
    sageStatusInFilter.forEach((x) => {
      if (x.label && x.value) {
        x.label = ToNormalizedState(x.label)
        const newLbl = x.label.toUpperCase()
        const newStatusLbl = translator.formatMessage({
          id: `ORDER.STATUS.${newLbl}`,
        })
        x.label = newStatusLbl
        x.value = newStatusLbl
        if (!status.find((st) => st.label === x.label)) {
          status.push(x)
        }
      }
    })
  }
  return (
    <div
      className='card-toolbar justify-content-center justify-content-lg-start'
      id='filterByStatus'
    >
      <div className='w-100 w-lg-auto'>
        <SuperSelect
          options={status}
          onChange={handleChangeSelected}
          placeholder={translator.formatMessage({id: 'GENERAL_FILTER_BY_STATUS'})}
          selectedOptions={null}
          forceValueByLocalStorageName={
            GetFilterForcedValue('forcedValueStatus') !== '-1' ? 'forcedValueStatus' : undefined
          }
          isClearable={true}
        ></SuperSelect>
      </div>
      {children}
    </div>
  )
}

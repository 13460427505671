/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { AllOrdersWithFilterModel, CustomerWithID, Order } from '../../models/allOrdersWithFilters'
import { useIntl } from 'react-intl'
import { OrderStatus } from '../../models/models'
import { Button, Col, Row } from 'react-bootstrap'
import SuperSelect from '../../components/SuperSelect'
import { Link } from 'react-router-dom'
import { requestDeleteOrder } from '../../requests/requests'
import { formatDate, formatDateType } from '../../helpers/formatDate'
import { ConfirmDeleteModal } from '../../components/generic-confirm/ConfirmDelete'
import { useBtnTitles } from '../../hooks/useBtnTitles'
import { PaginationWrapper } from '../../components/PaginationWrapper'
import { usePaginationForWrapper } from '../../hooks/usePaginationForWrapper'
import { FilterByStatus } from '../../components/FilterByStatus'
import { ToNormalizedState } from '../../helpers/utils'
import { FilterByExpeditionDate } from '../../components/FilterByExpeditionDate'
import { useSaveFilters } from '../../hooks/useSaveFilters'
import { SaveFilterForcedValue } from '../../helpers/filterForcedValue'

type Props = {
  className: string
  headerTitle: string
  headerDescription: string
  orders: AllOrdersWithFilterModel
  customers: CustomerWithID[]
  headers: string[]
  isFetching: boolean
  refetch: any
  filteredUser: string
  setFilteredUser: any
  filteredDate: Date | undefined
  setFilteredDate: any
}

const AllOrdersWithFiltersTable: React.FC<Props> = ({
  className,
  headerTitle,
  headerDescription,
  orders,
  customers,
  headers,
  isFetching,
  refetch,
  filteredUser,
  setFilteredUser,
  filteredDate,
  setFilteredDate,
}) => {
  const intl = useIntl()
  const filterByClientRef = useRef();
  const filterByStateRef = useRef();
  const [recoveredFilter, setRecoveredFilter] = useState<boolean>(false)
  const { saveFilter, getSavedFilter, existSavedFilter, removeSavedFilter } = useSaveFilters()
  const [filteredStatus, setFilteredStatus] = useState<Number | string>(-1)
  const btnTitles = useBtnTitles()
  const delayedRefetch = () => {
    setTimeout(() => {
      refetch()
    }, 100)
  }
  const handleChangeSelectedUser = (selected, ref) => {
    filterByClientRef.current = ref.current;
    if (selected) {
      setFilteredUser(selected.value)
      saveFilter('filteredUser', selected.value)
    } else {
      setFilteredUser('')
      saveFilter('filteredUser', '')
    }
    delayedRefetch()
  }
  if (orders) {
    orders.sort(function (a, b) {
      return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
    })
  }
  const currentFilteredUser = (): CustomerWithID => {
    const customer: CustomerWithID | undefined = customers.find((x) => x.id === filteredUser)
    if (customer) {
      SaveFilterForcedValue(filteredUser, 'forcedValueClient')
      return customer
    }
    return {} as CustomerWithID
  }
  const customerOptions = () => {
    if (!customers) {
      return {}
    }
    return [...customers].map((element) => ({
      value: element.id,
      label: `${element.businessName}`,
    }))
  }
  const handleConfirmDelete = async (orderId: string) => {
    await requestDeleteOrder(orderId)
    await refetch()
  }

  // Get a state for filters
  const orderGroupByState = orders.filter((value, index, array) => {
    return (
      array.findIndex((x) => x.sageOrderStatus === value.sageOrderStatus) === index &&
      value.sageOrderStatus != null
    )
  })

  const orderStates = orderGroupByState.map((x) => ({
    value: x.sageOrderStatus,
    label: x.sageOrderStatus,
  }))

  if (filteredStatus) {
    if (typeof filteredStatus == 'string') {
      const parsedInt = parseInt(filteredStatus)
      if (isNaN(parsedInt)) {
        orders = orders.filter(
          (x) => ToNormalizedState(x.sageOrderStatus) === ToNormalizedState(filteredStatus)
        )
      } else {
        if (parsedInt >= 0) {
          orders = orders.filter((x) => x.status === parsedInt)
        }
      }
    }
  }

  const filteredStatusLbl = isNaN(filteredStatus as number)
    ? ToNormalizedState(filteredStatus as string)
    : OrderStatus[filteredStatus as string]

  //const paginationObj = usePaginationForWrapper(orders, 20, filteredUser, 'customer-all-orders')
  const paginationObj = usePaginationForWrapper(orders, 20)
  useEffect(() => {
    paginationObj.refreshPagination()
  }, [paginationObj && paginationObj.currentItems.length])

  useEffect(() => {
    if (!recoveredFilter) {
      setRecoveredFilter(true)
      if (existSavedFilter('filteredUser')) {
        const filtered = getSavedFilter('filteredUser')
        setFilteredUser(filtered)
      }
      if (existSavedFilter('filteredStatus')) {
        const filtered = getSavedFilter('filteredStatus')
        setFilteredStatus(filtered)
      }
      if (existSavedFilter('filteredExpeditionDate')) {
        const filtered = new Date(getSavedFilter('filteredExpeditionDate'))
        setFilteredDate(filtered)
      }
    }
  }, [existSavedFilter, getSavedFilter, recoveredFilter, setFilteredDate, setFilteredUser])

  const anyFilteredStatusValid =
    (typeof filteredStatus === 'number' && filteredStatus >= 0) ||
    typeof filteredStatus === 'string'
  if (anyFilteredStatusValid) {
    SaveFilterForcedValue(filteredStatus, 'forcedValueStatus')
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <Row className='justify-content-start align-items-center w-100'>
          <Col>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>{headerTitle}</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>{headerDescription}</span>
              {(filteredDate || anyFilteredStatusValid || filteredUser.length > 0) && (
                <span className='fw-semibold fs-6 alert alert-primary mt-5'>
                  {intl.formatMessage({ id: 'GENERAL_SHOWING_ORDERS_OF' })}{' '}
                  {filteredUser.length > 0 && <>{currentFilteredUser().businessName}</>}
                  {filteredUser.length <= 0 && <>{intl.formatMessage({ id: 'GENERAL_ALL_CLIENTS' })}</>}
                  {filteredStatus && anyFilteredStatusValid && filteredStatusLbl && (
                    <span>
                      &nbsp;[
                      {intl.formatMessage({
                        id: 'ORDER.STATUS.' + filteredStatusLbl.toLocaleUpperCase(),
                      })}
                      ]
                    </span>
                  )}
                  {filteredDate && <>&nbsp; - {formatDateType(filteredDate)}</>}
                </span>
              )}
              {!(filteredDate || anyFilteredStatusValid || filteredUser.length > 0) && (
                <>
                  <span className='fw-semibold fs-6 alert alert-primary mt-5'>
                    {intl.formatMessage({ id: 'GENERAL_SHOWING_ORDERS_OF_LAST_7_DAYS' })}{' '}
                  </span>
                </>
              )}
            </h3>
          </Col>
        </Row>
        <Row className='justify-content-start align-items-center w-100'>
          <Col lg={'auto'} className='mb-3 mb-lg-0'>
            <FilterByStatus
              translator={intl}
              handleChangeSelected={(x, ref) => {
                filterByStateRef.current = ref.current;
                if (x) {
                  saveFilter('filteredStatus', x.value)
                  if (typeof x.value === 'number') {
                    setFilteredStatus(parseInt(x.value))
                  } else if (typeof x.value === 'string') {
                    setFilteredStatus(x.value)
                  }
                } else {
                  setFilteredStatus(-1)
                  removeSavedFilter('filteredStatus')
                }
              }}
              sageStatusInFilter={orderStates}
            >
            </FilterByStatus>
          </Col>
          <Col className='mb-3 mb-lg-0' lg='auto'>
            <FilterByExpeditionDate
              onSubmit={() => {
                delayedRefetch()
                const dateInput = document.querySelector(
                  'input[name="selectedDate"]'
                ) as HTMLInputElement
                if (dateInput) {
                  saveFilter('filteredExpeditionDate', dateInput.valueAsDate?.getTime() as number)
                }
              }}
              filteredDate={filteredDate}
              setFilteredDate={setFilteredDate}
              addDefaultHelperLabel={false}
            >
              <Col lg='auto'>
                <div className='card-toolbar justify-content-end'>
                  <div className='w-100'>
                    <SuperSelect
                      options={customerOptions()}
                      onChange={handleChangeSelectedUser}
                      placeholder={intl.formatMessage({ id: 'ALL_ORDERS_WITH_FILTERS_FILTER_BY_CUSTOMER' })}
                      selectedOptions={null}
                      forceValueByLocalStorageName='forcedValueClient'
                      isClearable={true}
                    ></SuperSelect>
                  </div>
                </div>
              </Col>
              {(filteredUser || (typeof filteredStatus === 'number' && filteredStatus >= 0) || filteredDate ||
                (typeof filteredStatus === 'string')) && (
                  <Col className='mt-2 mt-lg-0 mb-3 mb-lg-0' lg='auto'>
                    <Button
                      variant='secondary'
                      type='button'
                      className={`w-100`}
                      onClick={() => {
                        setFilteredUser('')
                        removeSavedFilter('filteredUser')
                        setFilteredStatus(-1)
                        removeSavedFilter('filteredStatus')
                        setFilteredDate('')
                        if (filterByClientRef && filterByClientRef.current) {
                          (filterByClientRef.current as any).clearValue()
                        }
                        if (filterByStateRef && filterByStateRef.current) {
                          (filterByStateRef.current as any).clearValue()
                        }
                        delayedRefetch()
                      }}
                    >
                      {intl.formatMessage({ id: 'MY_ORDERS_FILTER_CLEAR' })}
                    </Button>
                  </Col>
                )}
            </FilterByExpeditionDate>
          </Col>
        </Row>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {isFetching && (
            <div>
              <i className='fa fa-spinner fs-2x fa-sync fa-spin'></i>
            </div>
          )}
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted text-uppercase'>
                {headers.map((el, i) => (
                  <th className='min-w-150px' key={el + i}>
                    {el}
                  </th>
                ))}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {orders &&
                paginationObj.currentItems.map((order: Order) => (
                  <tr key={order.guidId} id={`tableOrderRow${order.guidId}`}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column text-dark text-hover-primary fs-6'>
                          {formatDate(order.dateCreated)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {order.customer.businessName}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {order.detail.clientBusinessName}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column text-dark text-hover-primary fs-6'>
                          {order.detail.shippingAddressDescription} (
                          {order.detail.shippingAddressSageCode})
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column text-dark text-hover-primary fs-6'>
                          {new Date(order.expeditionDate).toLocaleDateString('en-GB')}
                        </div>
                      </div>
                    </td>
                    {/* <td className='text-end'>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span
                            className={`badge badge-light-info`}
                            title={
                              order.completeOrder
                                ? 'En el caso que la pieza que dispongamos no alcance los kilos indicados, NO completar el pedido con más producto'
                                : 'En el caso que la pieza que dispongamos no alcance los kilos indicados, completar el pedido con más producto'
                            }
                          >
                            {order.completeOrder
                              ? intl.formatMessage({id: 'GENERAL_YES'})
                              : intl.formatMessage({id: 'GENERAL_NO'})}
                          </span>
                        </div>
                      </div>
                    </td> */}
                    {/* <td className='text-end'>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span
                            className={`badge badge-light-info`}
                            title={
                              order.splitPieceQuestion
                                ? 'En el caso que la pieza supere los kgs indicados cortar la pieza para que tenga un peso aproximado a lo deseado'
                                : 'En el caso que la pieza supere los kgs indicados lo quiero igualmente'
                            }
                          >
                            {order.splitPieceQuestion
                              ? intl.formatMessage({id: 'GENERAL_YES'})
                              : intl.formatMessage({id: 'GENERAL_NO'})}
                          </span>
                        </div>
                      </div>
                    </td> */}
                    <td className='text-end'>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {order.status === OrderStatus.Pending && (
                            <span className={`badge badge-light-warning`}>
                              <span>
                                {intl.formatMessage({
                                  id: 'ORDER.STATUS.' + OrderStatus[order.status].toUpperCase(),
                                })}
                              </span>
                            </span>
                          )}
                          {order.sageOrderStatus && order.status === OrderStatus.Validated && (
                            <span
                              className={`badge ${order.lines && order.lines.find((x) => x.invoices.length > 0)
                                ? 'badge-light-success'
                                : 'badge-light-primary'
                                }`}
                            >
                              <span>{order.sageOrderStatus}</span>
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className='text-start'>
                      {order.sageOrder && (
                        <span className={`badge badge-light-primary`}>
                          <span>{order.sageOrder}</span>
                        </span>
                      )}
                    </td>
                    <td>
                      <div className='d-flex justify-content-start flex-shrink-0'>
                        <Link
                          to={`/order-detail/${order.guidId}`}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          title={btnTitles.details}
                        >
                          <KTIcon iconName='eye' className='fs-3' />
                        </Link>
                        {/* {ShowPackagingListOrInvoiceInOrderList &&
                          order.status === OrderStatus.Validated &&
                          canShowInvoiceOrPackagingList(order.documents) && (
                            <>
                              <a
                                className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 position-relative ${
                                  generatingPDF ? 'disabled' : ''
                                }`}
                                title={btnTitles.invoice}
                                href='#invoice'
                                onClick={async (e) => {
                                  e.preventDefault()
                                  await generateInvoicePDF(
                                    setGeneratingPDF,
                                    setGeneratingOrderPDF,
                                    order,
                                    ''
                                  )
                                }}
                              >
                                {generatingPDF === 1 && generatingOrderPDF === order.guidId && (
                                  <Spinner
                                    className='position-absolute'
                                    animation='border'
                                  ></Spinner>
                                )}
                                <KTIcon iconName='document' className='fs-3' />
                              </a>
                            </>
                          )} */}

                        {/* {ShowPackagingListOrInvoiceInOrderList &&
                          order.status === OrderStatus.Validated &&
                          canShowInvoiceOrPackagingList(order.documents) && (
                            <>
                              <a
                                className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 position-relative ${
                                  generatingPDF ? 'disabled' : ''
                                }`}
                                title={btnTitles.packagingList}
                                href='#packaging-list'
                                onClick={async (e) => {
                                  e.preventDefault()
                                  await generatePackagingListPDF(
                                    setGeneratingPDF,
                                    setGeneratingOrderPDF,
                                    order,
                                    ''
                                  )
                                }}
                              >
                                {generatingPDF === 2 && generatingOrderPDF === order.guidId && (
                                  <Spinner
                                    className='position-absolute'
                                    animation='border'
                                  ></Spinner>
                                )}
                                <KTIcon iconName='questionnaire-tablet' className='fs-3' />
                              </a>
                            </>
                          )} */}
                        {order.status === OrderStatus.Pending && (
                          <>
                            <ConfirmDeleteModal
                              onConfirm={() => {
                                handleConfirmDelete(order.guidId)
                              }}
                              title={intl.formatMessage({ id: 'CONFIRM_TEXT' })}
                              message={intl.formatMessage({ id: 'BUTTON.CONFIRM_DELETE' })}
                            ></ConfirmDeleteModal>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        <PaginationWrapper {...paginationObj} paginationWrapperClass='my-5' />
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { AllOrdersWithFiltersTable }

import {Modal, Button, Form, Row, Col, Spinner} from 'react-bootstrap'
import {Formik, Field} from 'formik'
import {useIntl} from 'react-intl'
import {MyOrder} from '../../models/myOrders'
import {Client, ShippingAddress} from '../../models/models'
import {useQuery, useQueryClient} from 'react-query'
import {customerAddress, requestModifyOrder} from '../../requests/requests'
import {OrderEdit} from '../../models/orderEdit'
import {ToastifyCaller, ToastifyType} from '../Toastify'
import {useQueryValidateOrders} from '../../hooks/useQueryValidateOrders'
import { useSortAddresses } from '../../hooks/useSortedAddresses'

export const UpdateShippingAddressModal = ({
  show,
  onHide,
  refetch,
  changing,
  setChanging,
}: {
  show: MyOrder | undefined
  onHide: any
  refetch: any
  changing: boolean
  setChanging: any
}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  useQuery<Client[]>(
    ['selectedUserAddresses_EditShippingAddress_'+(show?.clientId ? show?.clientId : 'Disabled')],
    async () => {
      const {data: x} = await customerAddress(show?.clientId as string)
      return x
    },
    {staleTime: 60 * 60 * 24 * 365, enabled: !!show?.clientId}
  )
  const selectedUser: Client = queryClient.getQueryData([
    'selectedUserAddresses_EditShippingAddress_'+(show?.clientId ? show?.clientId : 'Disabled'),
  ]) as Client
  const {refetchValidateOrders} = useQueryValidateOrders(new Date())
  const isCheckedAddress = (shippingAddr: ShippingAddress) => {
    const selectedAddr = show?.detail.shippingAddressSageCode === shippingAddr.sageCode
    return selectedAddr
  }
  
  const isMainAddress = (shippingAddr: ShippingAddress): boolean => {
    const mainAddress = selectedUser?.shippingAddresses.find((x) => x.mainAddress)
    if (mainAddress) {
      return shippingAddr.sageCode === mainAddress.sageCode
    }
    return false
  }

  const {addresses} = useSortAddresses(selectedUser);

  return (
    <Modal
      show={show ? true : false}
      onHide={onHide}
      dialogClassName='modal-dialog modal-dialog-centered modal-lg'
    >
      <Modal.Header closeButton>
        <h2 className='text-black'>
          {intl.formatMessage({id: 'UPDATE_SHIPPING_ADDRESS_MODAL.TITLE'})}
        </h2>
      </Modal.Header>
      <Modal.Body className='modal-body py-lg-10 px-lg-20'>
        <Formik
          initialValues={{shippingAddressSageCode: show?.detail.shippingAddressSageCode}}
          onSubmit={async ({shippingAddressSageCode}) => {
            setChanging(true);
            if (shippingAddressSageCode && show && show.detail) {
              show.detail.shippingAddressSageCode = shippingAddressSageCode
              const orderEditObj = {
                guidId: show?.guidId,
                clientId: show?.clientId,
                expeditionDate: show?.expeditionDate,
                detail: show?.detail,
                lines: show?.lines,
              } as OrderEdit
              const response = await requestModifyOrder(orderEditObj)
              const allOK = response.status >= 200 && response.status
              if (allOK) {
                onHide()
                await refetch()
                await refetchValidateOrders()
                setChanging(false);
                ToastifyCaller(
                  intl.formatMessage({id: 'UPDATE_SHIPPING_ADDRESS_MODAL.UPDATE_OK'}),
                  ToastifyType.SUCCESS
                )
              }
            } else {
              console.log('Error on update shipping address')
            }
          }}
        >
          {({handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <Row className='mb-12'>
                {addresses.map((address: ShippingAddress) => (
                  <Col xs='12' lg='6' className='mb-4 position-relative' key={address.sageCode}>
                    <Field
                      type='radio'
                      className='position-absolute top-0'
                      style={{transform: 'translate(16px, 18px)', accentColor: '#0c2340'}}
                      name='shippingAddressSageCode'
                      value={address.sageCode}
                      id={`address-${address.sageCode}`}
                    />
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-outline-default p-4 d-flex h-100 px-13 ${
                        isCheckedAddress(address) ? 'active' : ''
                      } ${isMainAddress(address) ? 'bg-light-primary' : ''} ${address.isActive ? '' : 'opacity-50'}`}
                      htmlFor={`address-${address.sageCode}`}
                    >
                      <ul className='d-flex flex-column align-items-start list-unstyled'>
                        <li className='text-start lh-sm fw-normal'>
                          {address.description} ({address.sageCode})
                        </li>
                        <li className='text-start lh-sm fw-normal'>{address.address}</li>
                        <li className='text-start lh-sm fw-normal'>
                          {`${address.postalCode} · ${address.city}`}
                        </li>
                        <li className='text-start lh-sm fw-normal'>{address.countryName}</li>
                      </ul>
                    </label>
                  </Col>
                ))}
              </Row>

              <div className='d-flex justify-content-end'>
                <Button
                  type='reset'
                  className='btn btn-secondary btm-sm mt-8  me-4'
                  onClick={onHide}
                >
                  {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                </Button>
                <Button variant='primary' type='submit' className='btn btn-primary btm-sm mt-8' disabled={changing}>
                  {intl.formatMessage({id: 'BUTTON.SAVE'})}
                  {changing && <Spinner animation='border' className='mx-3' size='sm'/>}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

import axios from 'axios';
import {AuthModel, ChangePassword, ResetPassword, ResponseMessage} from './_models';

const {VITE_APP_API_URL:API_URL} = import.meta.env

export const LOGIN_URL = `${API_URL}/Account/Login`;
export const REGISTER_URL = `${API_URL}/Account/Register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/Account/ForgotPassword`;
export const REQUEST_RENEW_TOKEN = `${API_URL}/Account/RenewToken`;
export const REQUEST_PASSWORD_CHANGE_URL = `${API_URL}/Account/ChangePasswordWithCurrent`;
export const REQUEST_PASSWORD_RESET = `${API_URL}/Account/ResetPassword`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: AuthModel}>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function requestPasswordChange(changePassword:ChangePassword) {
  return axios.post<{result: ChangePassword}>(REQUEST_PASSWORD_CHANGE_URL, changePassword);
}

export function requestPasswordReset(changePassword:ResetPassword) {
  return axios.post<{result: ResetPassword | ResponseMessage}>(REQUEST_PASSWORD_RESET, changePassword);
}

export function requestRenewToken() {
  return axios.get<AuthModel>(REQUEST_RENEW_TOKEN);
}